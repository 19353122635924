import { Form } from 'antd';
import { UseNotificationFormProps } from '../utils/types';

export const useNotificationForm = ({ formName, onSubmit }: UseNotificationFormProps) => {
  const [form] = Form.useForm();

  const handleChangeSwitch = async (
    value: boolean,
    name: string,
    underFields: string[],
    disableSubmit: boolean = false
  ) => {
    if (value === false) {
      form.setFields(
        underFields.map((item: string) => ({
          name: item,
          errors: [],
        }))
      );
    }
    form.setFieldValue(name, value);
    if (!disableSubmit) {
      const values = await form.validateFields();
      await onSubmit(values, formName);
      form.setFieldsValue(values);
    }
  };

  const handleChangeField = async (value: any, name: string, disableSubmit: boolean = false) => {
    form.setFieldValue(name, value);
    if (!disableSubmit) {
      const values = await form.validateFields();
      await onSubmit(values, formName);
      form.setFieldsValue(values);
    }
  };

  const handleCancelBtn = () => {
    form.resetFields();
  };

  return {
    form,
    handleChangeSwitch,
    handleChangeField,
    handleCancelBtn,
  };
}; 