import { message, Modal } from "antd";
import axios, { AxiosError } from "axios";
import { sessionShopHash } from "../../../authServices/jwt";
import { StorageKeys } from "../../../constant/storageKeys";
import { axiosDashboard } from "../../../views/pages/Dashboard/config/AxiosSetting";
import {
  BASE_AUTH_API,
  axiosAuth,
} from "../../../views/pages/Dashboard/config/UserManagmentAxtios";
import { Role } from "./libs/Role.type";
import { UserResponse } from "./libs/UserResponse.type";
import { UpdateUserArg } from "../../../components/UserAdministration/libs/helpers/getAssignedRoles";
import { IStaff, StaffResponse } from "../../../components/UserAdministration/StaffTable";

const API_CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID as string;
const API_CLIENT_SECRET = process.env.REACT_APP_API_CLIENT_SECRET as string;

const { confirm } = Modal;


class UserManagmentService {
  async getAuthToken() {
    try {
      const url = `${BASE_AUTH_API}/auth/realms/twcMain/protocol/openid-connect/token`;
      const requestData = {
        grant_type: "client_credentials",
        client_id: API_CLIENT_ID,
        client_secret: API_CLIENT_SECRET,
      };
      const response = await axios.post(url, new URLSearchParams(requestData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      localStorage.setItem(
        StorageKeys.UserManagementAccess,
        response.data.access_token
      );

      return response.data;
    } catch (error) {
      console.error("Error making Axios request:", error);
    }
  }

  async getUserData<T = UserResponse>(query: string) {
    try {
      const url = `/auth/admin/realms/twcMain/${query}`;
      const response = await axiosAuth.get<T[]>(url);

      return response.data;
    } catch (error) {
      console.error("Error making Axios request:", error);
    }
  }

  async deleteUser(userId: string) {
    try {
      const url = `auth/admin/realms/twcMain/users/${userId}`;
      const response = await axiosAuth.delete(url);

      console.log(response.status);
    } catch (error) {
      console.error("Error making Axios request:", error);
    }
  }

  async deleteStaff(staffId: string) {
    try {
      const response = await axiosDashboard.delete(
        `/services/inventorysvc/api/staff/${staffId}`,
      );
      return response;
    } catch (error) {
      console.error("Error deleting staff:", error);
      message.error("Failed to delete staff");
    }
  }

  async deleteUserRole(userId: string, roleId: Role) {
    const auth_token = localStorage.getItem(StorageKeys.UserManagementAccess);

    this.deleteRoleToUser(auth_token, userId, roleId);
  }

  async getUserRolesByUserId(userId: string) {
    try {
      const res = await axiosAuth.get<Role[]>(
        `/auth/admin/realms/twcMain/users/${userId}/role-mappings/realm`
      );

      return res.data;
    } catch (error) {
      return null;
    }
  }

  async getAllRoles() {
    return this.getUserData<Role>("roles");
  }

  async addStaffUser(data: any, roleItems: Role[] | undefined) {
    try {
      const tenantid = sessionShopHash.getShopHash();
      const auth_token = localStorage.getItem(StorageKeys.UserManagementAccess);
      // const userData: any = await this.getUserDto(data);
      const userData = data;

      if (!tenantid) {
        message.error("Tenant not found. Please login again.");
        setTimeout(() => {
          window.location.href = "/auth/sign-in";
        }, 1500);
        return;
      }

      if (userData.dto.attributes.staffRef) {
        const staffData = {
          email: userData.dto.email,
          lastName: userData.dto.lastName,
          staffRef: userData.dto.attributes.staffRef,
          firstName: userData.dto.firstName,
          locationRef: userData.dto.attributes.store[0],
          locationsManaged: userData.dto.attributes.store,
        };

        if (userData?.isStaffExist) {
          const res = await this.updateStaff(staffData);
          if (!res.id) {
            throw new Error("Error updating staff");
          }
        } else {
          const res = await this.createStaff(staffData);
          if (!res.id) {
            throw new Error("Error updating staff");
          }
        }
      }

      await this.createUser(auth_token, userData.dto);

      const userCreationResponse = await this.getKeyloackUserInfo(
        tenantid,
        userData.dto.email,
        auth_token as string
      );

      const userId = userCreationResponse[0]?.id;

      if (userId && roleItems?.length) {
        for await (const roleItem of roleItems) {
          await this.assignRoleToUser(auth_token, userId, roleItem);
        }
      }

      message.success("User created successfully");
    } catch (error) {
      console.error("Error creating user:", error);
      message.error("Failed to create user");
    }
  }
  async registerUser(data: any) {
    try {
      // const tenantid = sessionShopHash.getShopHash();
      const auth_token = localStorage.getItem(StorageKeys.UserManagementAccess);
      const userData = await this.getUserDto(data);
      await this.createUser(auth_token, userData);

      message.success("Registration successful");
    } catch (error) {
      console.error("Registration failed:", error);
    }
  }

  async updateUser(data: any, roleItems: UpdateUserArg | null) {
    try {
      const auth_token = localStorage.getItem(StorageKeys.UserManagementAccess);
      const userData: any = await this.getUserDto(data);

      const userId = data?.userId;

      await axiosAuth.put(
        `/auth/admin/realms/twcMain/users/${userId}`,
        userData.dto,
        {
          headers: {
            Authorization: `Bearer ${auth_token}`,
          },
        }
      );

      if (roleItems) {
        for await (const roleItem of roleItems.deletedRoles) {
          await this.deleteRoleToUser(auth_token, userId, roleItem);
        }

        for await (const roleItem of roleItems.addedRoles) {
          await this.assignRoleToUser(auth_token, userId, roleItem);
        }
      }

      message.success("User updated successfully");
    } catch (error) {
      message.error("Failed to create user");
      console.error("Error creating user:", error);
    }
  }

  async findUserByEmail(email: string) {
    try {
      const authToken = localStorage.getItem(StorageKeys.UserManagementAccess);
      const response = await axiosAuth.get(
        `/auth/admin/realms/twcMain/users?email=${email}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      const users = response?.data || [];
      const foundUser = users.find((user: any) => user.email === email);

      return foundUser || null;
    } catch (error) {
      console.error("Error finding user by email:", error);
      return null;
    }
  }

  async resetUserPassword(userId: string) {
    const auth_token = localStorage.getItem(StorageKeys.UserManagementAccess);
    const res = await axiosAuth.put(
      `/auth/admin/realms/twcMain/users/${userId}/reset-password-email`,
      {
        headers: { Authorization: `Bearer ${auth_token}` },
      }
    );

    return res;
  }

  async updateStaff(staffData: any) {
    try {
      const tenantid = sessionShopHash.getShopHash();
      const authToken = localStorage.getItem(StorageKeys.UserManagementAccess);

      const { id, ...staffDataWithoutId } = staffData;

      const response = await axiosDashboard.put(
        `/services/inventorysvc/api/staff/id=${id}`,
        staffDataWithoutId,
        {
          headers: {
            "Content-Type": "application/json",
            "X-TWC-Tenant": tenantid,
            "Authorization": `Bearer ${authToken}`,
          },
          params: {
            id,
            staffRef: staffDataWithoutId?.staffRef,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error updating staff:", error);
    }
  }


  async getUserDto(data: any) {
    const tenantid = sessionShopHash.getShopHash();
    const { email, firstName, storeId, staffRef, lastName } = data;

    const attributes: any = {
      tenantid: [tenantid],
    };

    if (storeId) {
      const isStoreExist = await this.checkStoreExistence(Array.isArray(storeId) ? storeId[0] : storeId);
      if (!isStoreExist) {
        message.error("Store not found");
        return;
      }
      attributes.store = storeId;
    }
    let existingStaff: any = null;
    if (staffRef) {
      existingStaff = await this.checkStaffExistence(staffRef);

      if (existingStaff) {
        return new Promise((resolve, reject) => {
          confirm({
            title: `This staff identifier is currently in use by ${existingStaff?.firstName} ${existingStaff?.lastName} (${existingStaff?.email}). Do you wish to update or replace the existing data?`,
            onOk() {
              attributes.staffRef = staffRef;

              resolve({
                isStaffExist: !!existingStaff, dto: {
                  firstName: firstName,
                  lastName: lastName,
                  email,
                  enabled: true,
                  username: email,
                  attributes,
                  credentials: [
                    {
                      type: "password",
                      value: "vysakh@123",
                      temporary: true,
                    },
                  ],
                }
              });
            },
            onCancel() {
              reject();
            },
          });
        });
      }

      attributes.staffRef = staffRef;
    }

    const userData: any = {
      firstName: firstName,
      lastName: lastName,
      email,
      enabled: true,
      username: email,
      attributes,
      credentials: [
        {
          type: "password",
          value: "vysakh@123",
          temporary: true,
        },
      ],
    };

    return { dto: userData, isStaffExist: !!existingStaff };
  }

  private async checkStoreExistence(storeId: string) {
    try {
      const res = await axiosDashboard.get(
        `/services/inventorysvc/api/location?locationRef=${storeId}`
      );
      return res.status === 200;
    } catch (error) {
      return false;
    }
  }

  private async checkStaffExistence(staffRef: string) {
    try {
      const res = await axiosDashboard.get(`/services/inventorysvc/api/staff/${staffRef}/staffRef`);
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      return null;
    }
  }

  private async createUser(authToken: string | null, userData: any) {
    try {
      return await axiosAuth.post("/auth/admin/realms/twcMain/users", userData, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authToken}`,
        },
      });
    } catch (error: AxiosError | any) {
      if (axios.isAxiosError(error) && error.response) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 409:
            message.error("User exists with same username");
            break;
          case 500:
            message.error("Internal server error. Try again later.");
            break;
          default:
            message.error(`Error: ${error.response.statusText}`);
        }
      } else {
        message.error("Network error or server is unreachable.");
      }
      throw error;
    }
  }

  async createStaff(staffData: any) {
    try {
      const response = await axiosDashboard.post(
        "/services/inventorysvc/api/staff",
        staffData,
      );

      return response.data;
    } catch (error) {
      console.error("Error creating staff:", error);
    }
  }

  private async getKeyloackUserInfo(
    tenantid: string,
    email: string,
    auth_token: string
  ) {
    const encodedEmail = encodeURIComponent(email);
    const useResponse = await axiosAuth.get(
      `/auth/admin/realms/twcMain/users?username=${encodedEmail}`,
      {
        headers: {
          Authorization: `Bearer ${auth_token}`,
        },
      }
    );

    return useResponse.data;
  }

  async getAllStaff() {
    let allStaffs: IStaff[] = [];
    let lastItemId: string = "";
    let keepFetching = true;

    try {
      while (keepFetching) {
        const res = await axiosDashboard.get(
          `/services/inventorysvc/api/allStaff`,
          {
            params: {
              tenantId: sessionShopHash.getTenant(),
              pageSize: 50,
              lastItemId: lastItemId,
            },
          }
        );

        const response = res.data as StaffResponse;
        allStaffs = [...allStaffs, ...response.staffs];

        if (response.pageSize < 50) {
          keepFetching = false;
        } else {
          lastItemId = response.lastItemId as string;
        }
      }
      return {
        lastItemId: lastItemId,
        pageSize: allStaffs.length,
        staffs: allStaffs,
      };
    } catch (error) {
      console.error("Error getting all staff:", error);
      // message.error("Failed to get staff");
    }
  }

  private async assignRoleToUser(
    authToken: string | null,
    userId: string,
    roleItem: Role
  ) {
    return await axiosAuth.post(
      `/auth/admin/realms/twcMain/users/${userId}/role-mappings/realm`,
      [roleItem],
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }

  private async deleteRoleToUser(
    authToken: string | null,
    userId: string,
    roleItem: Role
  ) {
    return await axiosAuth.delete(
      `/auth/admin/realms/twcMain/users/${userId}/role-mappings/realm`,
      {
        data: [roleItem],
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  }
}

export const userManagementService = new UserManagmentService();
