import { Alert, Button, Form, Input, Spin } from "antd";
import { NotificationHeader } from "../shared/NotificationHeader";

interface AuthenticationSectionProps {
   form: any;
   keyLoaded: boolean;
   errorMsg: string;
   saveBtnLoading: boolean;
   onSubmit: (values: any) => Promise<void>;
   fields: Array<{
     name: string;
     label: string;
     rules: any[];
     isPassword?: boolean;
     prefix?: string;
     disabled?: boolean;
   }>;
   submitDisabled?: boolean;
   title?: string;
   description?: string;
   text?: string;
}

export const SharedAuthenticationSection = ({ 
  form, 
  keyLoaded, 
  errorMsg, 
  saveBtnLoading, 
  onSubmit,
  fields,
  submitDisabled,
  title = "Authentication",
  description = "Authentication details",
  text = "API credentials for your account."
}: AuthenticationSectionProps) => {
   return (
      <>
         <div className="grid sm:grid-cols-3 xs:grid-cols-3 gap-4 wrap">
            <NotificationHeader 
              title={title} 
              description={description} 
              text={text} 
            />
            <div className="col-span-2">
               <div className="bg-[#fff] px-7 p-5 text-base text-gray-500 !min-h-[80px]">
                  <Spin spinning={keyLoaded}>
                     <Form
                        name="basic"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 19 }}
                        className="keys-form"
                        form={form}
                        onFinish={onSubmit}
                        autoComplete="off"
                     >
                        {fields.map((field, index) => (
                          <>
                            <Form.Item
                              key={field.name}
                              label={field.label}
                              name={field.name}
                              className="keys-form-item !mb-1"
                              rules={field.rules}
                            >
                              {field.isPassword ? (
                                <Input.Password 
                                  prefix={field.prefix}
                                  disabled={field.disabled}
                                  autoComplete="off"
                                />
                              ) : (
                                <Input 
                                  prefix={field.prefix}
                                  disabled={field.disabled}
                                  autoComplete="off"
                                />
                              )}
                            </Form.Item>
                            {index < fields.length - 1 && <br />}
                          </>
                        ))}
                     </Form>
                  </Spin>
               </div>
               {errorMsg && <Alert className="!mt-2" message={"Warning - no authentication credentials found"} type="warning" showIcon />}
            </div>
         </div>
         <div className="btn-root !flex sm:!items-center !justify-end gap-3 my-2">
            <Button
               type="primary"
               className="save-btn md:!w-[15%] xs:!w-full !min-w-[15%] my-2"
               onClick={() => form.submit()}
               loading={!!saveBtnLoading}
               disabled={submitDisabled}
            >
               Save
            </Button>
         </div>
      </>
   );
}; 