import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { useTransformBarData } from "../hooks/useTransformStackBarData";
import { ClickHouseDashboardApi, DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import { truncateText } from "../helpers";
import { ChartType, StaffChartType } from "../../Dashboard/SimpleBoxMain/clickHouseApi/types";

export interface TransformedData {
   locationName: string;
   WISHLIST?: number;
   CUSTOMER?: number;
   WISHLISTITEM?: number;
   CUSTOMER_MESSAGE?: number;
}

const StackedBarChartWrapper: React.FC<{
   dateRange: DateRange;
   selectedLocation?: string[];
   selectedStaff?: string[];
   interactionType: ChartType | StaffChartType;
   isStaff?: boolean;
   multiple?: boolean
}> = ({ dateRange, selectedLocation, selectedStaff, interactionType, isStaff, multiple }) => {
   const [rawData, setRawData] = useState<TransformedData[]>([]);

   useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
         let response;
         if (isStaff) {
            response = await ClickHouseDashboardApi.getStaffGraphData(dateRange, interactionType as StaffChartType, selectedStaff);
         } else {
            response = await ClickHouseDashboardApi.getLocationGraphData(dateRange, interactionType as ChartType, selectedLocation);
         }
         if (isMounted) {
            setRawData(JSON.parse(response).dashboardChart);
         }
      };
      fetchData();

      return () => {
         isMounted = false;
      };
   }, [dateRange, selectedLocation, interactionType, isStaff, selectedStaff]);

   const data = useTransformBarData(rawData, {
      groupByField: isStaff ? "staff_name" : "locationName",
      sumFields: ["WISHLIST", "CUSTOMER", "WISHLISTITEM", "CUSTOMER_MESSAGE", "conversions", "totalAmount"],
      interactionType: interactionType,
   });

   return (
      <div style={{ overflowX: "auto" }}>
         <div style={{ width: "100%" }}>
            <ResponsiveContainer width="100%" height={600}>
               <BarChart
                  data={data}
                  margin={{
                     top: 20,
                     right: 30,
                     left: 20,
                     bottom: 60,
                  }}
               >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                     dataKey={isStaff ? "staff_name" : "locationName"}
                     tickFormatter={(name) => truncateText(name, 20)}
                     // @ts-ignore
                     tick={{ angle: -40, textAnchor: "end", fontSize: 12 }}
                     height={120}
                     interval={0}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {!multiple ? (
                     <>
                        <Bar dataKey="WISHLIST" stackId="a" fill="#82ca9d" name="Wishlist" />
                        <Bar dataKey="CUSTOMER_MESSAGE" stackId="a" fill="#ff7300" name="Customer Message" />
                     </>
                  ) : (
                     <>
                        <Bar dataKey="CUSTOMER" stackId="a" fill="#8884d8" name="Customer" />
                        <Bar dataKey="WISHLIST" stackId="a" fill="#82ca9d" name="Wishlist" />
                        <Bar dataKey="WISHLISTITEM" stackId="a" fill="#ffc658" name="Wishlist Item" />
                        <Bar dataKey="CUSTOMER_MESSAGE" stackId="a" fill="#ff7300" name="Customer Message" />
                     </>
                  )}
               </BarChart>
            </ResponsiveContainer>
         </div>
      </div>
   );
};

export default StackedBarChartWrapper;
