import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { ClickHouseDashboardApi, DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import { truncateText } from "../../Store&Staff/helpers";
import { QuoteChartType } from "../../Dashboard/SimpleBoxMain/clickHouseApi/types";
import { Tooltip as AntTooltip } from "antd";

interface DataItem {
   storeName: string;
   order_value: string;
   order_count: string;
}

const QuoteBarChart: React.FC<{
   dateRange: DateRange;
   selectedLocation?: string[];
   interactionType: QuoteChartType;
   title?: string;
   isWishlist?: boolean;
}> = ({ dateRange, selectedLocation, interactionType, title, isWishlist }) => {
   const [rawData, setRawData] = useState<DataItem[]>([]);

   useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
         let response;
         if (isWishlist) {
            response = await ClickHouseDashboardApi.getChartStats(dateRange);
         } else {
            response = await ClickHouseDashboardApi.getQuoteChartData(dateRange, interactionType, selectedLocation);
         }

         if (isMounted) {
            setRawData(JSON.parse(response).dashboardChart);
         }
      };
      fetchData();

      return () => {
         isMounted = false;
      };
   }, [dateRange, selectedLocation, interactionType, isWishlist]);

   const data = rawData
      ?.filter((item) => item.storeName)
      ?.map((item) => ({
         storeName: item.storeName,
         value: Number(item.order_value) || Number(item.order_count),
      }));

   return (
      <div style={{ overflowX: "auto" }}>
         <h3>{title}</h3>
         <AntTooltip title="Total value of orders that included a wishlisted item, by store, in the given period" placement="left">
            <div style={{ width: "100%" }}>
               <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                     data={data}
                     margin={{
                        top: 5,
                        right: 10,
                        left: 10,
                        bottom: 5,
                     }}
                  >
                     <CartesianGrid strokeDasharray="3 3" />
                     <XAxis
                        dataKey="storeName"
                        tickFormatter={(name) => truncateText(name, 20)}
                        // @ts-ignore
                        tick={{ angle: -40, textAnchor: "end", fontSize: 12 }}
                        height={120}
                        interval={0}
                     />
                     <YAxis />
                     <Tooltip />
                     <Bar dataKey="value" fill="#8884d8" />
                  </BarChart>
               </ResponsiveContainer>
            </div>
         </AntTooltip>
      </div>
   );
};

export default QuoteBarChart;
