export const useTransformLineData = (
   rawData: any[],
   options: { groupByField: string; sumFields: string[]; sortByField: string; interactionType: string }
): any[] => {
   const { groupByField, sumFields, sortByField, interactionType } = options;
   if (!rawData || rawData.length === 0) return [];
   const groupedData: Record<string, Record<string, number | string>> = {};

   const incrementField = (group: Record<string, number | string>, field: string, value: number) => {
      group[field] = (group[field] as number) + value;
   };

   rawData.forEach((item) => {
      const groupKey = item[groupByField];

      if (!groupKey || !item[groupByField]) return;

      if (!groupedData[groupKey]) {
         groupedData[groupKey] = {
            [groupByField]: groupKey,
            WISHLIST: 0,
            WISHLISTITEM: 0,
            CUSTOMER_MESSAGE: 0,
            C: 0,
            value: 0,
         };
      }

      const group = groupedData[groupKey];

      if (interactionType === "INTERACTION_BY_TYPE_PERDAY") {
         const entityType = item.entityType;
         const interactionCount = Number(item.C) || 0;

         if (sumFields.includes(entityType)) {
            incrementField(group, entityType, interactionCount);
         }
      } else if (interactionType === "CONVERSION_COUNT_PERDAY_STORE") {
         const conversionCount = Number(item.C) || 0;

         if (sumFields.includes(item.source)) {
            incrementField(group, item.source, conversionCount);
         }
      } else if (interactionType === "CONVERSION_VALUE_PERDAY_STORE") {
         const conversionValue = Number(item.value) || 0;

         if (sumFields.includes(item.source)) {
            incrementField(group, item.source, conversionValue);
         }
      }
   });

   let result = Object.values(groupedData);

   result = result.filter((item) => sumFields.some((field) => item[field] !== 0));

   if (sortByField) {
      result.sort((a, b) => new Date(a[sortByField]).getTime() - new Date(b[sortByField]).getTime());
   }

   return result;
};
