import { sessionShopHash } from "../../../authServices/jwt";
import { axiosDashboard } from "../../../views/pages/Dashboard/config/AxiosSetting";

export const postDotDigitalCredentials = async (data: any) => {
    try {
      const req = {
        apiUser: data?.apiUser,
        apiPassword: data?.apiPassword,
      };
      return await axiosDashboard.post(
        `/services/eventcollector/api/v1/marketing/dotdigital/byTenant?tenantId=${sessionShopHash.getTenant()}`,
        req
      );
    } catch (error) {
      return Promise.reject(error);
    }
  };
  
  export const getDotDigitalUser = async () => {
    try {
      return await axiosDashboard.get(
        `/services/eventcollector/api/v1/marketing/dotdigital/byTenant?tenantId=${sessionShopHash.getTenant()}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  };
  