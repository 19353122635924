import { useEffect, useState, useCallback } from "react";
import { ClickHouseDashboardApi, DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import Card from "../../Dashboard/SimpleBoxMain/Card";

interface DashboardData {
   totalQuotes: number;
   quotesConverted: number;
   totalRevenu: string;
   quotedValue: string;
   quotedProducts: number;
   quotedCustomer: number;
   customersConverted: number;
   avgQuotePerCustomer: string;
   avgConvertionRate: string;
   avgConvertionPeriod: string;
}

const QuoteCards = ({ dateRange, selectedLocation }: { dateRange: DateRange; selectedLocation: string[] }) => {
   const [data, setData] = useState<DashboardData | null>(null);
   const [loading, setLoading] = useState(false);

   const fetchData = useCallback(async () => {
      setLoading(true);
      try {
         const response = await ClickHouseDashboardApi.getQuoteStats(
            {
               from: dateRange.from,
               to: dateRange.to,
            },
            selectedLocation
         );
         setData(JSON.parse(response).dashboardData);
      } catch (error) {
         console.error("Error fetching data:", error);
      } finally {
         setLoading(false);
      }
   }, [dateRange, selectedLocation]);

   useEffect(() => {
      fetchData();
   }, [fetchData, dateRange]);

   const formatNumber = (num: number | string | undefined) => {
      if (num === undefined) return "N/A";
      return new Intl.NumberFormat().format(Number(num));
   };

   const formatCurrency = (num: number | string | undefined) => {
      if (num === undefined) return "N/A";
      return `$${new Intl.NumberFormat("en-US", {
         minimumFractionDigits: 0,
         maximumFractionDigits: 0,
      }).format(Number(num))}`;
   };

   return (
      <div className="grid mt-5">
         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 ">
            <Card loading={loading} title="Total Quotes" value={formatNumber(data?.totalQuotes)} />
            <Card loading={loading} title="Quoted Customers" value={formatNumber(data?.quotedCustomer)} />
            <Card loading={loading} title="Average quotes per customer" value={data?.avgQuotePerCustomer} />
            <Card loading={loading} title="Customers converted" value={formatNumber(data?.customersConverted)} />
            <Card loading={loading} title="Total Revenue" value={formatCurrency(data?.totalRevenu)} />
            <Card loading={loading} title="Quoted Products" value={formatNumber(data?.quotedProducts)} />
            <Card loading={loading} title="Quoted Value" value={formatCurrency(data?.quotedValue)} />
            <Card loading={loading} title="Quotes converted" value={formatNumber(data?.quotesConverted)} />
            <Card loading={loading} title="Avg Conversion Rate" value={parseFloat(data?.avgConvertionRate || '') * 100 + "%"} />
            <Card loading={loading} title="Average Conversion Days" value={parseInt(data?.avgConvertionPeriod || '')} />
         </div>
      </div>
   );
};

export default QuoteCards;
