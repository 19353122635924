import { useEffect, useState } from "react";
import { DatePicker, message, Select, Space } from "antd";
import { DateBeforeDays } from "../../Dashboard/components/Utils";
import { IStaff } from "../../../../components/UserAdministration/StaffTable";
import { userManagementService } from "../../../../api/services/userManagement/usere-management.service";
import { filterOptions } from "../../QuoteDashboard/utils/filterOptions";

const { Option } = Select;

interface DateRange {
   from: string;
   to: string;
}

const useStaffFilter = () => {
   const [staff, setStaff] = useState<IStaff[]>([]);
   const [selectedStaff, setSelectedStaff] = useState<string[]>([]);
   const [filterValue, setFilterValue] = useState<string>("30");
   const [dateValue, setDateValue] = useState<any>(null);
   const [dateRange, setDateRange] = useState<DateRange>({
      from: DateBeforeDays(30),
      to: DateBeforeDays(0),
   });

   const showCustomDateRange = filterValue === "custom";

   useEffect(() => {
      fetchStaff();
   }, []);

   const fetchStaff = async () => {
      try {
         let staff = await userManagementService.getAllStaff();

         if (!staff) return;
         const sortedStaff = staff?.staffs?.sort((a: IStaff, b: IStaff) => {
            const fullNameA = `${a?.firstName} ${a?.lastName}`.toUpperCase();
            const fullNameB = `${b?.firstName} ${b?.lastName}`.toUpperCase();
            return fullNameA?.localeCompare(fullNameB);
         });
         setStaff(sortedStaff);
         // const initialSelectedStaff = staff?.staffs?.slice(0, 10).map((staff) => staff?.staffRef);
         // setSelectedStaff(initialSelectedStaff);
      } catch (error) {
         message.error("Failed to fetch staff");
         console.error("Error fetching staff:", error);
      }
   };

   const handleFilterChange = (value: string) => {
      setFilterValue(value);
      if (value !== "custom") {
         setDateRange({
            from: DateBeforeDays(Number(value)),
            to: DateBeforeDays(0),
         });
      }
   };

   const handleDateRangeChange = (dates: any, dateStrings: string[]) => {
      setDateValue(dates);
      if (dates) {
         setDateRange({ from: dateStrings[0], to: dateStrings[1] });
      }
   };

   const filter = (
      <Space wrap>
         {showCustomDateRange && <DatePicker.RangePicker value={dateValue} onChange={handleDateRangeChange} />}
         <Select
            placeholder="Filter"
            className="w-full"
            value={filterValue}
            onChange={handleFilterChange}
            options={filterOptions}
         />
         <Select
            showSearch
            optionFilterProp="children"
            mode="multiple"
            placeholder="All"
            //@ts-ignore
            filterOption={(inputValue, option) => option!.children?.toUpperCase().includes(inputValue.toUpperCase())}
            className="w-full max-w-md min-w-[200px]"
            value={selectedStaff}
            onChange={setSelectedStaff}
         >
            {staff?.map((staff) => (
               <Option key={staff.staffRef} value={staff?.staffRef}>
                  {staff?.firstName + " " + staff?.lastName}
               </Option>
            ))}
         </Select>
      </Space>
   );

   return {
      selectedStaff,
      filterValue,
      dateRange,
      dateValue,
      handleFilterChange,
      handleDateRangeChange,
      filter,
   };
};

export default useStaffFilter;
