import { message } from "antd";
import axios from "axios";
import * as jose from "jose";

export const baseAPI = axios.create({
  baseURL: process.env.REACT_APP_CLICKHOUSE_BASE_API,
});

const reqData = { WasErr: false };

baseAPI.interceptors.request.use(
  async (config) => {
    let clickHouseSecretKey = localStorage.getItem("click-house-token");

    if (!clickHouseSecretKey) {
      clickHouseSecretKey = await generateJwtToken();
      localStorage.setItem("click-house-token", clickHouseSecretKey);
    }

    config.headers["Authorization"] = clickHouseSecretKey;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

baseAPI.interceptors.response.use(
  function (response) {
    reqData.WasErr = false;
    return response;
  },
  async function (error: any) {
    const originalRequest = error.config;

    const errorStatuses = [401, 403, 400, 500];
    if (errorStatuses.includes(error.response.status)) {
      if (window.location.pathname !== "/auth/sign-in") {
        window.location.href = '/auth/sign-in';
      }
      window.localStorage.clear();
    }

    if (error.response?.status === 503) {
      message.error("You have been logged out. Please log in again");
      if (window.location.pathname !== "/auth/sign-in") {
        window.location.href = '/auth/sign-in';
      }
    } else if (error.config && !reqData.WasErr) {
      reqData.WasErr = true;
      try {
        const token = await generateJwtToken();
        originalRequest.headers.Authorization = token;
        localStorage.setItem("click-house-token", token);
        return baseAPI.request(originalRequest);
      } catch (e) {
        alert("User is not authorized");
      }
    }

    return Promise.reject(error);
  }
);

const secretKey = process.env.REACT_APP_JWT_SECRET_KEY;
const secret = new TextEncoder().encode(secretKey);

const generateJwtToken = async () => {
  const token = await new jose.SignJWT({ "urn:example:claim": true })
    .setProtectedHeader({ alg: "HS256" })
    .setIssuedAt()
    .setIssuer("urn:example:issuer")
    .setAudience("urn:example:audience")
    .setExpirationTime(process.env.REACT_APP_JWT_EXPIRES || "1h")
    .sign(secret);

  return token;
};
