import { Button } from "antd";

interface LearnMoreBannerProps {
  text: string;
  subText: string;
}

export const LearnMoreBanner = ({ text, subText }: LearnMoreBannerProps) => {
  return (
    <div className="shadow-[0px_-1px_0px_2px_#59c3b8] p-[3%] px-[6%] !flex !flex-col gap-1">
      <div className="text-base text-gray-500">{text}</div>
      <div className="text-sm text-gray-600">{subText}</div>
      <Button className="!shadow-[0px_-0.5px_0px_1px_#59c3b8] !border-[#59c3b8] hover:!border-[#59c3b8] focus:!border-[#59c3b8] !bg-transparent !text-[#59c3b8] lg:!w-[20%] !min-w-[20%] my-2">
        Learn More
      </Button>
    </div>
  );
}; 