import { Card } from "antd";
import QuoteCards from "./components/QuoteCards";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import QuoteAreaChart from "./components/QuoteAreaChart";
import useStoreFilter from "../Store&Staff/hooks/useStoreFilter";
import QuoteBarChart from "./components/QuoteBarChart";
import { QuoteChartType } from "../Dashboard/SimpleBoxMain/clickHouseApi/types";

dayjs.extend(weekday);
dayjs.extend(localeData);

const QuoteDashboard = () => {
   const { selectedLocation, dateRange, filter } = useStoreFilter();

   return (
      <div className="m-8">
         <div className=" px-2 py-5 w-full">
            <div className="flex justify-between items-center">
               <h3 className="font-semibold text-xl mb-0">Quote Analysis</h3>
               {filter}
            </div>
            <QuoteCards selectedLocation={selectedLocation} dateRange={dateRange} />
         </div>
         <div className="w-full bg-white">
            <div className="px-4 w-full">
               <Card>
                  <Card>
                     <h3 className="font-semibold text-[18px]">Quotes per day</h3>
                     <QuoteAreaChart selectedLocation={selectedLocation} dateRange={dateRange} interactionType={QuoteChartType.QUOTES_PER_DAY} />
                  </Card>
                  <Card>
                     <h3 className="font-semibold text-[18px]">Conversions per day</h3>
                     <QuoteAreaChart selectedLocation={selectedLocation} dateRange={dateRange} interactionType={QuoteChartType.CONVERSIONS_PER_DAY} />
                  </Card>
                  <Card>
                     <h3 className="font-semibold text-[18px]">Revenue per day</h3>
                     <QuoteAreaChart selectedLocation={selectedLocation} dateRange={dateRange} interactionType={QuoteChartType.REVENUE_PER_DAY} />
                  </Card>
                  <Card>
                     <h3 className="font-semibold text-[18px]">Quote value by sale location</h3>
                     <QuoteBarChart
                        interactionType={QuoteChartType.QUOTE_VALUE_SALE_LOCATION}
                        dateRange={dateRange}
                        selectedLocation={selectedLocation}
                     />
                  </Card>
                  <Card>
                     <h3 className="font-semibold text-[18px]">Number of orders by sale location</h3>
                     <QuoteBarChart
                        interactionType={QuoteChartType.NUMBER_ORDER_SALE_LOCATION}
                        dateRange={dateRange}
                        selectedLocation={selectedLocation}
                     />
                  </Card>
               </Card>
            </div>
         </div>
      </div>
   );
};

export default QuoteDashboard;
