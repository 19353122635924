import { ClickHouseBaseApi } from "./clickHouseBaseApi";
import { ChartType, DashboardResponse, QuoteChartType, StaffChartType } from "./types";
import { baseAPI } from "./axiosSettings";
import { message } from "antd";
import { sessionShopHash } from "../../../../../authServices/jwt";

type ResultObject = {
  key: string;
  value: string | number;
  [key: string]: string | number;
};

export type DateRange = {
  from: string;
  to: string;
};

// export type ChartType = "INTERACTION_BY_TYPE" | "INTERACTION_BY_TYPE_PERDAY" | "INTERACTION_BY_STORE" | "MESSAGE_INTERACTION" | "CUSTOMER_INTERACTION" | "WISHLIST_INTERACTION" | "CONVERSION_STORE_NUMBER" | "CONVERSION_STORE_VALUE" | "CONVERSION_COUNT_PERDAY_STORE" | "CONVERSION_VALUE_PERDAY_STORE" | "CONVERSION_RATE" | "CONVERSION_BYDAY_BYSTORE" | "CONVERSION_VALUE_BYDAY_BYSTORE";

// export type StaffChartType = "INTERACTION_BY_STAFF" | "MESSAGE_INTERACTION" | "WISHLIST_INTERACTION" | "CUSTOMER_INTERACTION" | "STAFF_CONVERSION_LEADERBOAD" | "STAFF_CONVERSION_VALUE_LEADERBOAD";

// export type QuoteChartType = "QUOTES_PER_DAY" | "CONVERSIONS_PER_DAY" | "REVENUE_PER_DAY" | "NUMBER_ORDER_SALE_LOCATION" | "QUOTE_VALUE_SALE_LOCATION";

export type DashboardData = {
  wishListCustomers: string | number;
  totalWishLists: string | number;
  averageWishlistsPerCustomer: string | number;
  averageIteractionPerCustomer: string | number;
  totalWishListItems: string | number;
  productsWishList: string | number;
  valuePerWishList: string | number;
  avgProductsWLValue: string | number;
  totalValue: string | number;
  totalRevenue: string | number;
  avgOrderValue: string | number;
};

export class ClickHouseDashboardApi extends ClickHouseBaseApi {

  private async getDashboardDataQuery() {
    try {
      const result = await baseAPI.get<{ body: DashboardResponse }>(
        `CH?param1=DASHBOARD&tenantId=${this.tenantId}`
      );

      const data: DashboardResponse = await JSON.parse(
        result.data.body as unknown as string
      );
      return data.dashboardData;
    } catch (e) {
      console.log("getDashboardQuery:", e);
      return null;
    }
  }

  private converValue(value: string | number) {
    if (value === "") return value;

    const convertedValue = Number(value);

    return isFinite(convertedValue) ? convertedValue : "";
  }

  private convertSqlResult(resultObject: ResultObject) {
    for (let key in resultObject) {
      resultObject[key] = this.converValue(resultObject[key]);
    }

    return resultObject;
  }

  async getDashboardData() {
    let res = {} as DashboardData;

    // await this.start();

    let dashboardData = await this.getDashboardDataQuery();

    if (!dashboardData) {
      setTimeout(() => {
        this.getDashboardData();
      }, 3000);
      return;
    };

    res.wishListCustomers = dashboardData.totalCustomers;
    res.totalWishLists = dashboardData.totalWishlists;
    res.averageWishlistsPerCustomer = dashboardData.avgWLPerCustomer;
    res.averageIteractionPerCustomer = dashboardData.avgInteractionsOrCustomer;
    res.totalWishListItems = dashboardData.totalProducts;
    res.productsWishList = dashboardData.avgProductsPerWL;
    res.valuePerWishList = dashboardData.avgWLValue;
    res.totalValue = dashboardData.totalValue;
    res.totalRevenue = dashboardData.totalRevenue;
    res.avgOrderValue = dashboardData.avgOrderValue;

    const convertedData = this.convertSqlResult(res as any);

    return convertedData as unknown as DashboardData;
  }

  public static async getKpiStats(date: DateRange) {
    try {
      const response = await baseAPI.get(`CH`, {
        params: {
          tenantId: sessionShopHash.getTenant(),
          param1: "KPI",
          fromDate: date.from,
          toDate: date.to,
        }
      });
      return response.data.body;
    } catch (error) {
      console.error("Error fetching KPI data:", error);
      message.error("Error fetching KPI data");
    }

  }

  public static async getQuoteStats(date: DateRange, selectedLocations: string[]) {
    try {
      const response = await baseAPI.get(`CH`, {
        params: {
          tenantId: sessionShopHash.getTenant(),
          param1: "QUOTE",
          param5: selectedLocations ? `${selectedLocations.map(location => `'${location}'`).join(",")}` : undefined,
          fromDate: date.from,
          toDate: date.to,
        }
      });
      return response.data.body;
    } catch (error) {
      console.error("Error fetching Quote data:", error);
      message.error("Error fetching Quote data");
    }

  }

  public static async getChartStats(date: DateRange) {
    try {
      const response = await baseAPI.get(`CH`, {
        params: {
          tenantId: sessionShopHash.getTenant(),
          param1: "CHART",
          param2: "WISHLIST_SALE_BY_LOCATION",
          fromDate: date.from,
          toDate: date.to,
        }
      });
      return response.data.body;
    } catch (error) {
      console.error("Error fetching Quote data:", error);
      message.error("Error fetching Quote data");
    }

  }

  public static async getLocationGraphData(date: DateRange, chartType: ChartType, locationRef?: string[]) {
    try {
      const response = await baseAPI.get(`CH`, {
        params: {
          param2: chartType,
          param1: "LOCATION_GRAPH",
          tenantId: sessionShopHash.getTenant(),
          param5: locationRef ? `${locationRef.map(location => `'${location}'`).join(",")}` : undefined,
          fromDate: date.from,
          toDate: date.to,
        }
      });
      return response.data.body;
    } catch (error) {
      console.error("Error fetching Location Graph data:", error);
      message.error("Error fetching Location Graph data");
    }
  }

  public static async getStaffGraphData(date: DateRange, chartType: StaffChartType, staffsRef?: string[]) {
    try {
      const response = await baseAPI.get(`CH`, {
        params: {
          param2: chartType,
          param1: "STAFF_GRAPH",
          tenantId: sessionShopHash.getTenant(),
          param6: staffsRef ? `${staffsRef.map(staff => `'${staff}'`).join(",")}` : undefined,
          fromDate: date.from,
          toDate: date.to,
        }
      });
      return response.data.body;
    } catch (error) {
      console.error("Error fetching Location Graph data:", error);
      message.error("Error fetching Location Graph data");
    }
  }

  public static async getQuoteChartData(date: DateRange, chartType: QuoteChartType, locationRef?: string[]) {
    try {
      const response = await baseAPI.get(`CH`, {
        params: {
          param2: chartType,
          param1: "QUOTE_CHART",
          tenantId: sessionShopHash.getTenant(),
          param5: locationRef ? `${locationRef.map(location => `'${location}'`).join(",")}` : undefined,
          fromDate: date.from,
          toDate: date.to,
        }
      });
      return response.data.body;
    } catch (error) {
      console.error("Error fetching Location Graph data:", error);
      message.error("Error fetching Location Graph data");
    }
  }
}
