import React, { useEffect, useState } from "react";
import { ClickHouseDashboardApi, DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import { StaffChartType } from "../../Dashboard/SimpleBoxMain/clickHouseApi/types";

interface LeaderboardProps {
   dateRange: DateRange;
   leaderboardType: StaffChartType.STAFF_CONVERSION_LEADERBOAD | StaffChartType.STAFF_CONVERSION_VALUE_LEADERBOAD;
}

interface StaffData {
   staffRef: string;
   staffName: string;
   conversion_count?: string;
   total_amount?: string;
}

const Leaderboard: React.FC<LeaderboardProps> = ({ dateRange, leaderboardType }) => {
   const [staffData, setStaffData] = useState<StaffData[]>([]);
   const [loading, setLoading] = useState<boolean>(true);

   useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
         try {
            const response = await ClickHouseDashboardApi.getStaffGraphData(dateRange, leaderboardType);
            const parsedData = JSON.parse(response)?.dashboardChart;
            if (isMounted) {
               setStaffData(parsedData);
            }
         } catch (error) {
            console.error("Error fetching leaderboard data:", error);
         } finally {
            setLoading(false);
         }
      };

      fetchData();

      return () => {
         isMounted = false;
      };
   }, [dateRange, leaderboardType]);

   if (loading) {
      return <div className="text-center">Loading...</div>;
   }

   if (!staffData || staffData.length === 0) {
      return <div className="text-center">No data available</div>;
   }

   const isConversionCount = leaderboardType === "STAFF_CONVERSION_LEADERBOAD";

   return (
      <div className="w-full max-w-2xl bg-white shadow-md rounded-lg max-h-[500px] overflow-y-auto">
         <div className="sticky top-0 bg-white">
            <table className="min-w-full bg-white">
               <thead className="sticky top-0 bg-gray-200 z-10">
                  <tr>
                     <th className="py-2 px-4 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Staff Name</th>
                     <th className="py-2 px-4 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                        {isConversionCount ? "Total Conversions" : "Conversion Value $"}
                     </th>
                  </tr>
               </thead>
            </table>
         </div>
         <table className="min-w-full bg-white">
            <tbody>
               {staffData
                  .filter((staff) => staff?.staffName)
                  .map((staff, index) => (
                     <tr key={index} className={`border-t ${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
                        <td className="py-2 px-4 text-sm text-gray-900">{staff?.staffName}</td>
                        <td className="py-2 px-4 text-sm text-right text-gray-900">
                           {isConversionCount ? staff?.conversion_count : parseFloat(staff?.total_amount || "").toFixed(2)}
                        </td>
                     </tr>
                  ))}
            </tbody>
         </table>
      </div>
   );
};

export default Leaderboard;
