import { LoginResponse } from "../../api/services/keycloack/libs/types/LoginResponse";
import { isPrefix } from "../../components/UserAdministration/libs/helpers/isPrefix";
import { StorageKeys } from "../../constant/storageKeys";
import { UserJwtPayload } from "../../utility/interface/model/userJwtPayload";
import { LocalStorageKeys } from "./libs/LocalStorage.enum";
import { jwtDecode } from "jwt-decode";

const tokenKey = "ACCESS_TOKEN";
const appInfoKey = "STORE_INFO";
const shopHashKey = "SHOP_HASH";
export const fromShop = "FROM_SHOP";

export class JwtService {
  static getJwtPayload<T = any>(jwtToken: string): T {
    return jwtDecode(jwtToken);
  }

  static getUserRole(roles: string[]) {
    const twcRoles: string[] = [];

    for (let role of roles) {
      if (!isPrefix("twc", role)) continue;

      twcRoles.push(role);
    }

    return twcRoles;
  }
}

class AuthLocalStorage {
  setDataFromKeyloack(response: LoginResponse): void {
    const payload = JwtService.getJwtPayload<UserJwtPayload>(
      response.access_token
    );

    const shop_hash = payload.tenantid;
    sessionShopHash.setShopHash(shop_hash);
    window.localStorage.setItem(StorageKeys.Tenant, shop_hash);
    window.localStorage.setItem(
      StorageKeys.KeycloackUser,
      JSON.stringify(payload)
    );

    this.setAuthTokens(response.access_token, response.refresh_token);
  }

  getToken(): string | null {
    return window.localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
  }

  setToken(token: string): void {
    window.localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, token);
  }

  getSessionAppInside(): string | null {
    return window.localStorage.getItem(LocalStorageKeys.SESSION_INSIDE);
  }

  setSessionAppInside(hash: string): void {
    window.localStorage.setItem(LocalStorageKeys.SESSION_INSIDE, hash);
  }

  clearSessionAppInside(): void {
    window.localStorage.removeItem(LocalStorageKeys.SESSION_INSIDE);
  }

  setShopHash(shopHash: string): void {
    sessionShopHash.setShopHash(shopHash);
    window.localStorage.setItem(StorageKeys.Tenant, shopHash);
  }

  getShopHash(): string | null {
    return window.localStorage.getItem(StorageKeys.Tenant);
  }

  getRefreshToken(): string | null {
    return window.localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);
  }

  async getKeycloakUser(): Promise<UserJwtPayload | null> {
    const data = window.localStorage.getItem(StorageKeys.KeycloackUser);
    return data ? await JSON.parse(data) : null;
  }

  setRefreshToken(token: string): void {
    window.localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, token);
  }

  clearTokens(): void {
    window.localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
    window.localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
  }

  setAuthTokens(accessToken: string, refreshToken: string): void {
    this.setToken(accessToken);
    this.setRefreshToken(refreshToken);
  }
}

const sessionAppInfo: sessionAppInfoInterface<IAppInfo> = {
  getInfo() {
    const data = window.sessionStorage.getItem(appInfoKey);
    return data ? JSON.parse(data) : { store_hash: "", app_big_id: "" };
  },
  setInfo(data) {
    window.sessionStorage.setItem(appInfoKey, JSON.stringify(data));
  },
  clearInfo() {
    window.sessionStorage.removeItem(appInfoKey);
  },
};

const sessionShopHash: ISessionShopHash<string> = {
  getShopHash() {
    return window.localStorage.getItem(shopHashKey) || "";
  },
  getTenant() {
    return localStorage.getItem("SHOP_HASH") || localStorage.getItem(StorageKeys.Tenant) || "";
  },
  setShopHash(shopHash) {
    window.localStorage.setItem(shopHashKey, shopHash);
  },
  clearShopHash() {
    window.localStorage.removeItem(shopHashKey);
  },
};

const sessionToken: sessionTokenInterface = {
  getToken() {
    return window.sessionStorage.getItem(tokenKey) || "";
  },
  setToken(token) {
    window.sessionStorage.setItem(tokenKey, token);
  },
  clearToken() {
    window.sessionStorage.removeItem(tokenKey);
  },
};

export interface IAppInfo {
  store_hash?: string;
  app_big_id?: string | number;
}

interface sessionAppInfoInterface<T> {
  getInfo(): T;

  setInfo(token: T): void;

  clearInfo(): void;
}

interface ISessionShopHash<T> {
  getTenant(): T;

  getShopHash(): T;

  setShopHash(shopHash: T): void;

  clearShopHash(): void;
}

interface sessionTokenInterface {
  getToken(): string;

  setToken(token: string): void;

  clearToken(): void;
}

export { sessionAppInfo, sessionToken, sessionShopHash };
export const authLocalStorage = new AuthLocalStorage();
