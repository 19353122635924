import { LocalStorageKeys } from "../../authServices/jwt/libs/LocalStorage.enum";
import { CategoryKeys } from "../../configs/data/category.enum";
import { RolePermissions } from "../../configs/rolePemisions";
import { checkModuleAccess } from "../helpers/checkModuleAccess";

export const getUserVisitPermission = (roles: string[], pageName: string): boolean => {
  if (pageName === CategoryKeys.STORE_STAFF_DASHBOARD) return checkModuleAccess("storePos");
  if (pageName === CategoryKeys.APP_CONFIGURATIONS) {
    const twcConfig = JSON.parse(localStorage.getItem(LocalStorageKeys.TWC_CONFIG) || ''); 
    return twcConfig?.shopifyToken?.length > 0;
  };
  if (pageName === CategoryKeys.QUOTE_DASHBOARD) return checkModuleAccess("quotes");

  const allowedPages: string[] = roles.reduce((acc, role) => {
    const pages = RolePermissions[role as keyof typeof RolePermissions];
    return pages ? acc.concat(pages) : acc;
  }, [] as string[]);

  return allowedPages.includes(pageName);
};
