import { NotificationType } from "./types";

export const NOTIFICATION_STATUS = {
  initialized: "INITIALIZED",
  savedKeys: "SAVED_KEYS",
  enabled: "ENABLED",
} as const;

export const INITIAL_NOTIFICATION_LOADING_STATE: Record<NotificationType, boolean> = {
  REMINDER: false,
  PRICE_DROP: false,
  LOW_STOCK: false,
  BACK_IN_STOCK: false,
  ADD_TO_WISHLIST: false,
  POST_STORE_VISIT: false,
  NOTIFY_ME: false,
  COMING_SOON: false,
};

export const INITIAL_FIELD_CHANGE_STATE: Record<NotificationType, boolean> = {
  REMINDER: false,
  PRICE_DROP: false,
  LOW_STOCK: false,
  BACK_IN_STOCK: false,
  ADD_TO_WISHLIST: false,
  POST_STORE_VISIT: false,
  NOTIFY_ME: false,
  COMING_SOON: false,
};

export const MIN_DELAY_MINUTES = 5;
export const MIN_REMINDER_DAYS = 1; 