import { sessionShopHash } from "../../../authServices/jwt";
import { LocationResponse } from "../../../components/UserAdministration/libs/Location.type";
import { axiosDashboard } from "../../../views/pages/Dashboard/config/AxiosSetting";



class LocationService {
  async getLocationsByRef(locationRef: string) {
    return await axiosDashboard.get("services/inventorysvc/api/location", {
      params: {
        locationRef,
      },
    });
  }

  getAllLocations = (pageSize: number = 10, lastItemId?: string) => {
    return axiosDashboard.get<LocationResponse>(
      `services/inventorysvc/api/allLocations`,
      {
        params: {
          tenantId: sessionShopHash.getTenant(),
          pageSize,
          lastItemId,
        },
      }
    );
  };
}

export const locationService = new LocationService();
