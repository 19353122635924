import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { ClickHouseDashboardApi, DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import { truncateText } from "../helpers";
import { ChartType } from "../../Dashboard/SimpleBoxMain/clickHouseApi/types";

interface DataItem {
   locationName: string;
   interactions: number;
}

const BarChartWrapper: React.FC<{ dateRange: DateRange; selectedLocation: string[]; interactionType: ChartType; title?: string }> = ({
   dateRange,
   selectedLocation,
   interactionType,
   title,
}) => {
   const [rawData, setRawData] = useState<DataItem[]>([]);

   useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
         const response = await ClickHouseDashboardApi.getLocationGraphData(dateRange, interactionType, selectedLocation);
         if (isMounted) {
            setRawData(JSON.parse(response).dashboardChart);
         }
      };
      fetchData();

      return () => {
         isMounted = false;
      };
   }, [dateRange, selectedLocation, interactionType]);

   const data = rawData
      ?.filter((item) => item.locationName)
      ?.map((item) => ({
         locationName: item.locationName,
         interactions: Number(item.interactions),
      }));

   return (
      <div style={{ overflowX: "auto" }}>
         <h3 className="font-bold">{title}</h3>
         <div style={{ width: "100%" }}>
            <ResponsiveContainer width={500} height={400}>
               <BarChart
                  data={data}
                  margin={{
                     top: 5,
                     right: 10,
                     left: 10,
                     bottom: -40,
                  }}
               >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                     dataKey="locationName"
                     tickFormatter={(name) => truncateText(name, 15)}
                     // @ts-ignore
                     tick={{ angle: -45, textAnchor: "end", fontSize: 10 }}
                     height={120}
                     interval={0}
                  />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="interactions" fill="#8884d8" />
               </BarChart>
            </ResponsiveContainer>
         </div>
      </div>
   );
};

export default BarChartWrapper;
