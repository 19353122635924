import { LogoutOutlined, ShopOutlined, CheckSquareOutlined, ApartmentOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { ConfigurationIcon } from "../../assets/icon/ConfigurationIcon";
import { DashboardIcon } from "../../assets/icon/DashboardIcon";
import { DocumentationIcon } from "../../assets/icon/DocumentationIcon";
import { ReportingIcon } from "../../assets/icon/ReportIcon";
import { UserIcon } from "../../assets/icon/UserIcon";

interface category {
   id?: string;
   key?: string;
   icon?: React.ReactNode;
   label?: string;
   items?: subCategory[];
}

interface subCategory {
   key?: string;
   tooltip?: string;
   label?: string;
   url?: boolean;
   items?: subCategory[];
}

export const categories: category[] = [
   {
      id: "1",
      key: "wishlist-dashboard",
      icon: <DashboardIcon />,
      label: "wishlist-dashboard",
      items: [],
   },
   {
      id: "2",
      key: "top-stores-dashboard",
      icon: <DashboardIcon />,
      label: "Top Stores Dashboard",
      items: [],
   },
   {
      id: "3",
      key: "store-&-staff-dashboard",
      icon: <DashboardIcon />,
      label: "Store & Staff dashboard",
      items: [],
   },
   {
      id: "4",
      key: "quote-dashboard",
      icon: <DashboardIcon />,
      label: "quote-dashboard",
      items: [],
   },
   // {
   //   id: "2",
   //   key: "subscription",
   //   icon: <SubscriptionIcon />,
   //   label: "subscription",
   //   items: [],
   // },
   {
      id: "5",
      key: "integrations",
      icon: <ConfigurationIcon />,
      label: "Integrations",
      items: [],
   },
   {
      id: "6",
      key: "app-configurations",
      icon: <ConfigurationIcon />,
      items: [{ key: "launch-point" }, { key: "add-items-to-wishlist" }, { key: "wishlist-page" }],
      label: "app configuration",
   },
   // {
   //   id: "9",
   //   key: "notifications",
   //   icon: <ConfigurationIcon />,
   //   items: [{ key: "wishlist" }, { key: "quotes" }],
   //   label: "app configuration",
   // },
   {
      id: "7",
      key: "manage-users",
      icon: <UserIcon />,
      label: "Manage Users",
      items: [],
   },
   {
      id: "8",
      key: "manage-store-staff",
      icon: <UserIcon />,
      label: "Manage Store Staff",
      items: [],
   },
   {
      id: "9",
      key: "manage-stores-&-regions",
      icon: <ShopOutlined style={{ fontSize: "20px" }} />,
      label: "Store & Region Management",
      items: [],
   },
   // {
   //    id: "10",
   //    key: "task-management",
   //    icon: <CheckSquareOutlined style={{ fontSize: "20px" }} />,
   //    label: "Task Management",
   //    items: [
   //       {
   //          label: "Manage templates",
   //          key: "manage-templates",
   //          tooltip: "",
   //       },
   //       {
   //          label: "Manual tasks",
   //          key: "manual-tasks",
   //          tooltip: "",
   //       },
   //       {
   //          label: "Automated tasks",
   //          key: "automated-tasks",
   //          tooltip: "",
   //       },
   //       {
   //          label: "Task status",
   //          key: "task-status",
   //          tooltip: "",
   //       },
   //    ],
   // },
   {
      id: "11",
      key: "webhook-management",
      icon: <ApartmentOutlined style={{ fontSize: "20px" }} />,
      label: "Webhook Management",
      items: [],
   },
   // {
   //    id: "12",
   //    key: "list-management",
   //    icon: <UnorderedListOutlined style={{ fontSize: "22px" }} />,
   //    label: "List Management",
   //    items: [],
   // },
   {
      id: "13",
      key: "store-message-templates",
      icon: <ConfigurationIcon />,
      label: "Store Message Templates",
      items: [],
   },
   {
      id: "14",
      key: "reporting",
      icon: <ReportingIcon />,
      label: "reporting",
      items: [
         {
            key: "quotes",
            tooltip: "View and download all quotes created in a given period",
         },
         {
            key: "quote-conversion",
            tooltip: "View and download all converted quotes in a given period including sales person and store that raised the quote",
         },
         {
            key: "preferences",
            tooltip:
               "The preferences report allows you to select a combination of customer preferences and see a list of all customers that have all of those preferences selected",
         },
         {
            key: "customer-query",
            tooltip: "Search for and download the wishlist details for a specific customer",
         },
         {
            key: "customer-subscriptions",
            label: "Customer Subscriptions",
            url: false,
            items: [
               // {
               //    key: "customer-subscriptions-by-product",
               //    label: "Subscriptions By Product",
               //    tooltip:
               //       "View and download (or push to your marketing platform) the list of customers that have registered their interest in a product, or subscribed to be notified when an item is back in stock",
               // },
               {
                  key: "customer-subscriptions-by-date",
                  label: "Subscriptions By Date",
                  tooltip: "View and download the list of customers that have subscribed to notifications during a specific date range",
               },
               {
                  label: "Subscriptions Detail by Date",
                  key: "subscriptions-detail-by-date",
                  tooltip: "View and download the list of customers that have subscribed to notifications during a specific date range",
               },
               {
                  label: "Subscription Product Query",
                  key: "subscription-product-query",
                  tooltip: "View currently active notify me or coming soon counts by product",
               },
            ],
         },
         {
            key: "customer-message",
            tooltip: "View and download all messages sent and received by location and staff member for a given period",
         },
         {
            label: "Product Query",
            key: "product-report",
            tooltip: "View the popularity of a specific product, and download a list of customers that have that product on a wishlist",
         },
         {
            key: "top-brands",
            tooltip: "View and download the most popular brands across your wishlists for a given period",
         },
         {
            key: "top-products",
            tooltip: "View and download the most popular products across your wishlists for a given period",
         },
         {
            key: "top-customer-by-spend",
            tooltip: "View and download the top customers by spend for a given period",
         },
         {
            key: "product-conversion",
            tooltip: "View and download a list of the highest converting products in a given period",
         },
         {
            key: "product-conversion-detail",
            tooltip:
               "View and download every wishlist conversion including product, customer, store and staff (where available) during a given period",
         },
         // {
         //   key: "all-customers",
         //   tooltip:
         //     "A summary of all customers that created wishlists in a given date range",
         // },
         {
            key: "customer-conversions",
            tooltip:
               "View and download a list of your top customers based on the number of items or total value they purchased from wishlists, during a given period ",
         },
         {
            key: "wishlist",
            tooltip:
               "View and download a list of all wishlists created during a given period, including the customer name and the items currently on the wishlist",
         },

         {
            key: "interactions",
            tooltip:
               "View and download a list of all interactions during a given period, including the type of interaction, customer, product, store and staff (where available",
         },
      ],
   },
   // {
   //   id: "3",
   //   key: "pos-config",
   //   icon: <ConfigurationIcon />,
   //   items: [
   //     { key: "staff" },
   //     { key: "outlet-store" },
   //     { key: "outlet-config" },
   //   ],
   //   label: "Outlet",
   // },

   {
      id: "15",
      key: "documentation",
      icon: <DocumentationIcon />,
      label: "documentation",
      items: [],
   },
   // {
   //   id: "7",
   //   key: "diagnostics",
   //   icon: <IntegrationIcon />,
   //   label: "Diagnostics",
   //   items: [],
   // },
   {
      id: "16",
      key: "logout",
      icon: <LogoutOutlined style={{ fontSize: "20px" }} />,
      // items: [{ key: "sign-out" }],
      items: [],
   },
];
