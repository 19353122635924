import { CategoryKeys } from "../data/category.enum";
import { Roles } from "./roles.enum";

export const RolePermissions = {
  [Roles.TwcReportsUser]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.REPORTING,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.TOP_STORES_DASHBOARD,
    CategoryKeys.QUOTE_DASHBOARD,
    CategoryKeys.QUOTE_REPORT,
    CategoryKeys.QUOTE_CONVERSION_REPORT,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcGroupReports]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.REPORTING,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.TOP_STORES_DASHBOARD,
    CategoryKeys.QUOTE_DASHBOARD,
    CategoryKeys.QUOTE_REPORT,
    CategoryKeys.QUOTE_CONVERSION_REPORT,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcStoreOwner]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.TOP_STORES_DASHBOARD,
    CategoryKeys.MANAGE_STORES,
    CategoryKeys.STORE_MESSAGE_TEMPLATES,
    CategoryKeys.TASK_MANAGEMENT,
    CategoryKeys.TASK_MANAGEMENT_TEMPLATES,
    CategoryKeys.TASK_MANAGEMENT_MANUAL_TASKS,
    CategoryKeys.TASK_MANAGEMENT_AUTOMATED_TASKS,
    CategoryKeys.TASK_MANAGEMENT_TASK_STATUS,
    CategoryKeys.WEBHOOK_MANAGEMENT,
    CategoryKeys.LIST_MANAGEMENT,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.QUOTE_DASHBOARD,
    CategoryKeys.QUOTE_REPORT,
    CategoryKeys.QUOTE_CONVERSION_REPORT,
    CategoryKeys.INTEGRATIONS,
    CategoryKeys.REPORTING,
    CategoryKeys.MANAGE_USERS,
    CategoryKeys.MANAGE_STORE_STAFF,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.NOTIFICATIONS,
    CategoryKeys.APP_CONFIGURATIONS,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcGroupOwner]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.TOP_STORES_DASHBOARD,
    CategoryKeys.MANAGE_STORES,
    CategoryKeys.WEBHOOK_MANAGEMENT,
    CategoryKeys.LIST_MANAGEMENT,
    CategoryKeys.STORE_MESSAGE_TEMPLATES,
    CategoryKeys.TASK_MANAGEMENT,
    CategoryKeys.TASK_MANAGEMENT_TEMPLATES,
    CategoryKeys.TASK_MANAGEMENT_MANUAL_TASKS,
    CategoryKeys.TASK_MANAGEMENT_AUTOMATED_TASKS,
    CategoryKeys.TASK_MANAGEMENT_TASK_STATUS,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.MANAGE_STORE_STAFF,
    CategoryKeys.QUOTE_DASHBOARD,
    CategoryKeys.QUOTE_REPORT,
    CategoryKeys.QUOTE_CONVERSION_REPORT,
    CategoryKeys.INTEGRATIONS,
    CategoryKeys.REPORTING,
    CategoryKeys.MANAGE_USERS,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.NOTIFICATIONS,
    CategoryKeys.APP_CONFIGURATIONS,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcStoreUser]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.REPORTING,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.TOP_STORES_DASHBOARD,
    CategoryKeys.QUOTE_DASHBOARD,
    CategoryKeys.QUOTE_REPORT,
    CategoryKeys.QUOTE_CONVERSION_REPORT,
    CategoryKeys.MANAGE_STORES,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.STORE_MESSAGE_TEMPLATES,
    CategoryKeys.LIST_MANAGEMENT,
    CategoryKeys.TASK_MANAGEMENT,
    CategoryKeys.TASK_MANAGEMENT_TEMPLATES,
    CategoryKeys.TASK_MANAGEMENT_MANUAL_TASKS,
    CategoryKeys.TASK_MANAGEMENT_AUTOMATED_TASKS,
    CategoryKeys.TASK_MANAGEMENT_TASK_STATUS,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.NOTIFICATIONS,
    CategoryKeys.INTEGRATIONS,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcGroupUser]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.REPORTING,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.TOP_STORES_DASHBOARD,
    CategoryKeys.QUOTE_DASHBOARD,
    CategoryKeys.QUOTE_REPORT,
    CategoryKeys.QUOTE_CONVERSION_REPORT,
    CategoryKeys.MANAGE_STORES,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.STORE_MESSAGE_TEMPLATES,
    CategoryKeys.TASK_MANAGEMENT,
    CategoryKeys.TASK_MANAGEMENT_TEMPLATES,
    CategoryKeys.TASK_MANAGEMENT_MANUAL_TASKS,
    CategoryKeys.TASK_MANAGEMENT_AUTOMATED_TASKS,
    CategoryKeys.TASK_MANAGEMENT_TASK_STATUS,
    CategoryKeys.MANAGE_STORE_STAFF,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.NOTIFICATIONS,
    CategoryKeys.INTEGRATIONS,
    CategoryKeys.LOGOUT,
  ],
  [Roles.StoreOwner]: [...Object.values(CategoryKeys)],
  [Roles.TwcSuperUser]: [...Object.values(CategoryKeys)],
  [Roles.OldAuthMethod]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.TOP_STORES_DASHBOARD,
    CategoryKeys.MANAGE_STORES,
    CategoryKeys.STORE_MESSAGE_TEMPLATES,
    CategoryKeys.TASK_MANAGEMENT,
    CategoryKeys.TASK_MANAGEMENT_TEMPLATES,
    CategoryKeys.TASK_MANAGEMENT_MANUAL_TASKS,
    CategoryKeys.TASK_MANAGEMENT_AUTOMATED_TASKS,
    CategoryKeys.TASK_MANAGEMENT_TASK_STATUS,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.QUOTE_DASHBOARD,
    CategoryKeys.QUOTE_REPORT,
    CategoryKeys.QUOTE_CONVERSION_REPORT,
    CategoryKeys.SUBSCRIPTION,
    CategoryKeys.INTEGRATIONS,
    CategoryKeys.NOTIFICATIONS,
    CategoryKeys.REPORTING,
    CategoryKeys.POS_CONFIG,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.LOGOUT,
    CategoryKeys.MANAGE_USERS,
    CategoryKeys.MANAGE_STORE_STAFF,
  ],
};
