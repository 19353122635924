export type QueryParams = Record<string, unknown> | undefined;

export type DashboardData = {
  totalCustomers: number;
  totalWishlists: number;
  totalProducts: number;
  totalUniqueProducts: number;
  totalRevenue: number;
  totalValue: string;
  avgProductsPerWL: string;
  avgWLValue: string;
  avgWLPerCustomer: string;
  avgInteractionsOrCustomer: string;
  avgOrderValue: string;
};

export type DashboardResponse = {
  dashboardData: DashboardData;
};

export type FetchTableRquestParams = {
  param1: string;
  fromDate: string;
  toDate: string;
  pageSize?: string;
  pageNumber?: string;
  tenantId?: string;
  param2?: string | null;
  param3?: string | null;
  param4?: string | null;
  param5?: string | null;
  param6?: string | null;
  param7?: string | null;
  tableId?: string;
};
export type FetchTableDataParams = Omit<FetchTableRquestParams, "tenantId">;

export type FetchWishlistItemsParams = Omit<FetchTableRquestParams, "pageSize" | "pageNumber" | "tenantId">

export type TableData = Record<string, any>;

export enum ChartType {
  INTERACTION_BY_TYPE = "INTERACTION_BY_TYPE",
  INTERACTION_BY_TYPE_PERDAY = "INTERACTION_BY_TYPE_PERDAY",
  INTERACTION_BY_STORE = "INTERACTION_BY_STORE",
  MESSAGE_INTERACTION = "MESSAGE_INTERACTION",
  CUSTOMER_INTERACTION = "CUSTOMER_INTERACTION",
  WISHLIST_INTERACTION = "WISHLIST_INTERACTION",
  CONVERSION_STORE_NUMBER = "CONVERSION_STORE_NUMBER",
  CONVERSION_STORE_VALUE = "CONVERSION_STORE_VALUE",
  CONVERSION_COUNT_PERDAY_STORE = "CONVERSION_COUNT_PERDAY_STORE",
  CONVERSION_VALUE_PERDAY_STORE = "CONVERSION_VALUE_PERDAY_STORE",
  CONVERSION_RATE = "CONVERSION_RATE",
  CONVERSION_BYDAY_BYSTORE = "CONVERSION_BYDAY_BYSTORE",
  CONVERSION_VALUE_BYDAY_BYSTORE = "CONVERSION_VALUE_BYDAY_BYSTORE",
}

export enum StaffChartType {
  INTERACTION_BY_STAFF = "INTERACTION_BY_STAFF",
  MESSAGE_INTERACTION = "MESSAGE_INTERACTION",
  WISHLIST_INTERACTION = "WISHLIST_INTERACTION",
  CUSTOMER_INTERACTION = "CUSTOMER_INTERACTION",
  STAFF_CONVERSION_LEADERBOAD = "STAFF_CONVERSION_LEADERBOAD",
  STAFF_CONVERSION_VALUE_LEADERBOAD = "STAFF_CONVERSION_VALUE_LEADERBOAD",
}

export enum QuoteChartType {
  QUOTES_PER_DAY = "QUOTES_PER_DAY",
  CONVERSIONS_PER_DAY = "CONVERSIONS_PER_DAY",
  REVENUE_PER_DAY = "REVENUE_PER_DAY",
  NUMBER_ORDER_SALE_LOCATION = "NUMBER_ORDER_SALE_LOCATION",
  QUOTE_VALUE_SALE_LOCATION = "QUOTE_VALUE_SALE_LOCATION",
}

