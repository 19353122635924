import React, { useEffect, useState, useMemo } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { ClickHouseDashboardApi, DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import { formatXAxis } from "../../Store&Staff/helpers";
import { QuoteChartType } from "../../Dashboard/SimpleBoxMain/clickHouseApi/types";

interface ChartData {
   D: string;
   C: string;
   Sum: string;
   value: string;
}

interface QuoteAreaChartProps {
   dateRange: DateRange;
   selectedLocation: string[];
   interactionType: QuoteChartType;
}

const QuoteAreaChart: React.FC<QuoteAreaChartProps> = ({ dateRange, selectedLocation, interactionType }) => {
   const [rawData, setRawData] = useState<ChartData[]>([]);

   useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
         try {
            const response = await ClickHouseDashboardApi.getQuoteChartData(dateRange, interactionType, selectedLocation);
            const data = JSON.parse(response).dashboardChart;

            if (isMounted) {
               setRawData(data);
            }
         } catch (error) {
            console.error("Failed to fetch data:", error);
         }
      };

      fetchData();

      return () => {
         isMounted = false;
      };
   }, [dateRange, selectedLocation, interactionType]);

   console.log(rawData);
   

   const transformData = useMemo(() => {
      if (!rawData || rawData.length === 0) return [];

      return rawData
         .filter((item) => (item.C ? item.C !== "0" : item.Sum !== "\\N"))
         .map((item) => ({
            date: item.D,
            value: Number(item.C) || Number(item.Sum),
         }));
   }, [rawData]);

   console.log(transformData);
   

   return (
      <div style={{ overflowX: "auto" }}>
         <div style={{ width: "100%" }}>
            <ResponsiveContainer width="100%" height={400}>
               <AreaChart
                  data={transformData}
                  margin={{
                     top: 10,
                     right: 30,
                     left: 0,
                     bottom: 0,
                  }}
               >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tickFormatter={formatXAxis} />
                  <YAxis />
                  <Tooltip />
                  <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
               </AreaChart>
            </ResponsiveContainer>
         </div>
      </div>
   );
};

export default QuoteAreaChart;
