import KpiStats from "./charts/KpiStats";
import StaffPerformance from "./StaffPerformance";
import StorePerformance from "./StorePerformance";

const StoreStaffDashboard = () => (
   <div className="m-8">
      <div className="grid grid-cols-1 gap-4">
         <KpiStats />
         <div>
            <StorePerformance />
            <StaffPerformance />
         </div>
      </div>
   </div>
);

export default StoreStaffDashboard;
