import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { ClickHouseDashboardApi, DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import { useTransformLineData } from "../hooks/useTransformLineData";
import { formatXAxis } from "../helpers";
import { ChartType } from "../../Dashboard/SimpleBoxMain/clickHouseApi/types";

interface ChartData {
   D: string;
   WISHLIST?: number;
   WISHLISTITEM?: number;
   CUSTOMER_MESSAGE?: number;
}

const LineChartWrapper: React.FC<{ dateRange: DateRange; selectedLocation: string[]; interactionType: ChartType }> = ({
   dateRange,
   selectedLocation,
   interactionType,
}) => {
   const [rawData, setRawData] = useState<ChartData[]>([]);

   useEffect(() => {
      let isMounted = true;
      const fetchData = async () => {
         const response = await ClickHouseDashboardApi.getLocationGraphData(dateRange, interactionType, selectedLocation);
         const data = JSON.parse(response).dashboardChart;
         if (isMounted) {
            setRawData(data);
         }
      };
      fetchData();

      return () => {
         isMounted = false;
      };
   }, [dateRange, selectedLocation, interactionType]);

   const data = useTransformLineData(rawData, {
      groupByField: "D",
      sumFields: ["WISHLIST", "WISHLISTITEM", "CUSTOMER_MESSAGE", "C", "value"],
      sortByField: "D",
      interactionType,
   });

   return (
      <div style={{ overflowX: "auto" }}>
         <div style={{ width: "100%" }}>
            <ResponsiveContainer width="100%" height={400}>
               <LineChart
                  width={1000}
                  height={400}
                  data={data}
                  margin={{
                     top: 5,
                     right: 30,
                     left: 20,
                     bottom: 5,
                  }}
               >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="D" tickFormatter={formatXAxis} />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="WISHLIST" stroke="#8884d8" activeDot={{ r: 8 }} name="WISHLIST" />
                  <Line type="monotone" dataKey="WISHLISTITEM" stroke="#82ca9d" name="WISHLISTITEM" />
                  <Line type="monotone" dataKey="CUSTOMER_MESSAGE" stroke="#ff7300" name="CUSTOMER_MESSAGE" />
               </LineChart>
            </ResponsiveContainer>
         </div>
      </div>
   );
};

export default LineChartWrapper;
