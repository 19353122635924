import { LeftOutlined } from "@ant-design/icons";
import { Divider, Form } from "antd";
import { Link } from "react-router-dom";
import { NotificationHeader } from "../components/shared/NotificationHeader";
import { ReminderNotification } from "../components/NotificationTypes/ReminderNotification";
import { PriceDropNotification } from "../components/NotificationTypes/PriceDropNotification";
import { LowStockNotification } from "../components/NotificationTypes/LowStockNotification";
import { BackInStockNotification } from "../components/NotificationTypes/BackInStockNotification";
import { AddItemNotification } from "../components/NotificationTypes/AddItemNotification";
import { StoreVisitNotification } from "../components/NotificationTypes/StoreVisitNotification";
import { NotifyMeNotification } from "../components/NotificationTypes/NotifyMeNotification";
import { ComingSoonNotification } from "../components/NotificationTypes/ComingSoonNotification";
import { SharedAuthenticationSection } from "../components/Authentication/SharedAuthenticationSection";
import { NOTIFICATION_STATUS } from "../utils/constants";
import { useDotdigitalIntegration } from "../hooks/useDotdigitalIntegration";
import { LearnMoreBanner } from "../components/shared/LearnMoreBanner";
import { DotdigitalNotificationValues, NotificationType } from "../utils/types";
import '../utils/AppIntegrations.styles.css'

function DotdigitalIntegration() {
   const [form] = Form.useForm();
   const [reminderForm] = Form.useForm();
   const [priceDropForm] = Form.useForm();
   const [lowStockForm] = Form.useForm();
   const [backInStockForm] = Form.useForm();
   const [addItemForm] = Form.useForm();
   const [storeVisitForm] = Form.useForm();
   const [notifyMeForm] = Form.useForm();
   const [comingSoonForm] = Form.useForm();

   const {
      keyLoaded,
      dataLoaded,
      errorMsg,
      saveBtnLoading,
      notifSaveLoading,
      fieldOnChange,
      status,
      dotdigitalUser,
      handleSubmitKeys,
      handleNotificationChanges,
      notificationDetails,
      getData
   } = useDotdigitalIntegration();

   const getInitialValues = (type: string) => {
      const detail = notificationDetails?.find(d => d.notification === type);
      if (!detail) return {};

      const values = {
         active: detail.is_active,
         configurable_delay: detail.configurable_delay ? Number(detail.configurable_delay) : undefined,
         minStockLevelSchedule: detail.stockThreshold,
         OutOfStock: detail.outOfStock,
         template_id: detail.template_id
      };

      // Special case for reminder notification - convert minutes to days
      if (type === 'REMINDER' && detail.configurable_delay) {
         return {
            ...values,
            configurable_delay: Number(detail.configurable_delay) / (24 * 60)
         };
      }

      return values;
   };

   const notificationValues: DotdigitalNotificationValues = {
      apiUser: Form.useWatch("apiUser", form),
      apiPassword: Form.useWatch("apiPassword", form),
      configurable_delay: Form.useWatch("configurable_delay", form),
      sendReminderEmailInDays: Form.useWatch("schedule", reminderForm),
      sendReminderEmailInMinutes: Form.useWatch("configurable_delay", reminderForm),
      sendReminderNotification: Form.useWatch("active", reminderForm),
      OutOfStock1: Form.useWatch("OutOfStock", reminderForm),
      OutOfStock2: Form.useWatch("OutOfStock", priceDropForm),
      OutOfStock3: Form.useWatch("OutOfStock", lowStockForm),
      template_id1: Form.useWatch("template_id", reminderForm),
      template_id2: Form.useWatch("template_id", priceDropForm),
      template_id3: Form.useWatch("template_id", lowStockForm),
      template_id4: Form.useWatch("template_id", backInStockForm),
      template_id5: Form.useWatch("template_id", addItemForm),
      template_id6: Form.useWatch("template_id", storeVisitForm),
      template_id7: Form.useWatch("template_id", notifyMeForm),
      template_id8: Form.useWatch("template_id", comingSoonForm),
      sendPriceDropNotification: Form.useWatch("active", priceDropForm),
      delayPriceDropNotificationInMinutes: Form.useWatch("configurable_delay", priceDropForm),
      minStockLevelToTriggeredForLowStock: Form.useWatch("minStockLevelSchedule", lowStockForm),
      delayLowStockNotificationInMinutes: Form.useWatch("configurable_delay", lowStockForm),
      sendLowStockNotification: Form.useWatch("active", lowStockForm),
      minStockLevelToTriggeredForBackInStock: Form.useWatch("minStockLevelSchedule", backInStockForm),
      sendBackInStockNotification: Form.useWatch("active", backInStockForm),
      delayBackStockkNotificationInMinutes: Form.useWatch("configurable_delay", backInStockForm),
      enableAddItemNotification: Form.useWatch("active", addItemForm),
      waitTimeForAddItemNotification: Form.useWatch("configurable_delay", addItemForm),
      enablePostStoreVisitNotification: Form.useWatch("active", storeVisitForm),
      waitTimeForStoreVisitNotification: Form.useWatch("configurable_delay", storeVisitForm),
      notifyDelayNotification: Form.useWatch("configurable_delay", notifyMeForm),
      sendNotifyMe: Form.useWatch("active", notifyMeForm),
      sendComingSoon: Form.useWatch("active", comingSoonForm),
      notifyMeMinStockLevel: Form.useWatch("minStockLevelSchedule", notifyMeForm),
   };

   const authFields = [
     {
       name: "apiUser",
       label: "API user",
       rules: [
         {
           required: true,
           message: "Please input your API user!",
         },
         {
           min: 6,
           message: "Invalid format: Field should consist of 6 characters",
         },
       ]
     },
     {
       name: "apiPassword",
       label: "Password",
       isPassword: true,
       rules: [
         {
           required: true,
           message: "Please input your password!",
         },
         {
           min: 8,
           message: "Invalid format: Field should consist atleast 8 characters",
         },
       ]
     }
   ];

   const handleNotificationUpdate = async (values: any, type: NotificationType) => {
      await handleNotificationChanges(values, type);
      // Refetch data after successful save
      await getData();
   };

   return (
      <div className="p-[3%] px-[4%] leading-10">
         <Link className="flex items-center gap-1 !text-[#59c3b8]" to="/integrations">
            <LeftOutlined />
            Integrations
         </Link>
         <h1 className="font-medium text-3xl">Configure Dotdigital</h1>

         <LearnMoreBanner 
           text="Before you enable Dotdigital, enter all the details and configure supported triggers."
           subText="Refer our help docs to see how to build experiences using Dotdigital."
         />

         <Divider />

         <div className="grid sm:grid-cols-3 xs:grid-cols-3 gap-4 wrap">
            <NotificationHeader
              title="General"
              description="Integration status"
              text="Indicates if the integration is currently enabled for your store."
            />
            <div className="bg-[#fff] flex items-center px-7 text-base text-gray-500 col-span-2 !min-h-[80px]">
               <span>
                  Dotdigital integration on your store is &nbsp;
                  <b>{status.includes(NOTIFICATION_STATUS.savedKeys) ? "ENABLED" : "DISABLED"}</b>
               </span>
            </div>
         </div>

         <Divider />

         <SharedAuthenticationSection
                        form={form}
            keyLoaded={keyLoaded}
            errorMsg={errorMsg}
            saveBtnLoading={saveBtnLoading}
            onSubmit={handleSubmitKeys}
            fields={authFields}
            submitDisabled={!(notificationValues.apiUser && notificationValues.apiPassword)}
            title="Authentication"
            description="Authentication details"
            text="API credentials for your Dotdigital account."
         />

         <Divider />

         <div className="grid sm:grid-cols-3 xs:grid-cols-3 gap-4 wrap">
            <NotificationHeader
               title="Notifications"
               description="Configure email notifications to send to customers"
               text="Specify the criteria for each notification type."
            />
   
            <div className="col-span-2">
               <LearnMoreBanner 
                 text="Please setup experiences for the enabled events on Dotdigital as well to complete integration."
                 subText="Refer our tips to setup these events on Dotdigital."
               />
               <br />

               <div className="wrap bg-[#fff] p-6 px-7">
                  <ReminderNotification
                     initialValues={getInitialValues('REMINDER')}
                     onSubmit={handleNotificationUpdate}
                     notificationValues={notificationValues}
                     notifSaveLoading={notifSaveLoading}
                     fieldOnChange={fieldOnChange}
                  />

                     <Divider />

                  <PriceDropNotification
                     initialValues={getInitialValues('PRICE_DROP')}
                     onSubmit={handleNotificationUpdate}
                     notificationValues={notificationValues}
                     notifSaveLoading={notifSaveLoading}
                     fieldOnChange={fieldOnChange}
                  />

                     <Divider />

                  <LowStockNotification
                     initialValues={getInitialValues('LOW_STOCK')}
                     onSubmit={handleNotificationUpdate}
                     notificationValues={notificationValues}
                     notifSaveLoading={notifSaveLoading}
                     fieldOnChange={fieldOnChange}
                  />

                     <Divider />

                  <BackInStockNotification
                     initialValues={getInitialValues('BACK_IN_STOCK')}
                     onSubmit={handleNotificationUpdate}
                     notificationValues={notificationValues}
                     notifSaveLoading={notifSaveLoading}
                     fieldOnChange={fieldOnChange}
                  />

                     <Divider />

                  <AddItemNotification
                     initialValues={getInitialValues('ADD_TO_WISHLIST')}
                     onSubmit={handleNotificationUpdate}
                     notificationValues={notificationValues}
                     notifSaveLoading={notifSaveLoading}
                     fieldOnChange={fieldOnChange}
                  />

                     <Divider />

                  <StoreVisitNotification
                     initialValues={getInitialValues('POST_STORE_VISIT')}
                     onSubmit={handleNotificationUpdate}
                     notificationValues={notificationValues}
                     notifSaveLoading={notifSaveLoading}
                     fieldOnChange={fieldOnChange}
                  />

                     <Divider />

                  <NotifyMeNotification
                     initialValues={getInitialValues('NOTIFY_ME')}
                     onSubmit={handleNotificationUpdate}
                     notificationValues={notificationValues}
                     notifSaveLoading={notifSaveLoading}
                     fieldOnChange={fieldOnChange}
                  />
                  
                  <ComingSoonNotification
                     initialValues={getInitialValues('COMING_SOON')}
                     onSubmit={handleNotificationUpdate}
                     notificationValues={notificationValues}
                     notifSaveLoading={notifSaveLoading}
                     fieldOnChange={fieldOnChange}
                  />
               </div>
            </div>
         </div>
      </div>
   );
}

export default DotdigitalIntegration;
