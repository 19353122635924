import { baseAPI } from "./axiosSettings";

interface DataItem {
  s: string;
  d: string;
}

interface TransformedDataItem {
  xValue: string;
  yValue: {
    value: number;
    label: string;
  };
}

export type JsonGraphObject = {
  data: TransformedDataItem[] | null;
  totalValue: string;
  horizontalTitle: string;
  verticalTitle: string;
};

type DashBoardDataParams = {
  param2: string;
  fromDate: string;
  toDate: string;
};

type DashboardChartResponse = {
  dashboardChart: DataItem[];
};

export class ClickHouseBaseApi {
  protected tenantId: string;

  constructor(tenantId: string) {
    this.tenantId = tenantId;
  }

  private async getChartDataQuery(params: DashBoardDataParams) {
    const result = await baseAPI.get(`CH`, {
      params: {
        param2: params.param2,
        tenantId: this.tenantId,
        param1: "CHART",
        fromDate: params.fromDate,
        toDate: params.toDate,
      },
    });

    try {
      const data = (await JSON.parse(
        result?.data?.body
      )) as unknown as DashboardChartResponse;

      return data?.dashboardChart;
    } catch {
      return null;
    }
  }

  private getGraphFromSqlQuery(
    data: DataItem[],
    graphLabel: string
  ): TransformedDataItem[] {
    const result: TransformedDataItem[] = [];

    data.forEach((item) => {
      if (!item?.d || !item?.s) return;
      const xValue = item.d.split(" ")[0];

      const yValue = {
        value: parseInt(item.s),
        label: "Value",
      };

      result.push({
        xValue,
        yValue: yValue,
      });
    });

    return result;
  }

  private calculateTotalValue(data: TransformedDataItem[]): number {
    let totalValue = 0;

    data.forEach((item) => {
      totalValue += item.yValue.value;
    });

    return totalValue;
  }

  private async getConvertedInJsonGraphs(
    graphLabel: string,
    params: DashBoardDataParams
  ): Promise<JsonGraphObject> {
    let res = await this.getChartDataQuery(params);
    let data = null;
    let totalValue = 0;

    if (res) {
      data = this.getGraphFromSqlQuery(res, graphLabel);
      totalValue = this.calculateTotalValue(data);
    }

    return {
      data,
      totalValue: String(totalValue),
      horizontalTitle: "Date",
      verticalTitle: graphLabel,
    };
  }

  private generateDateArray(startDate: string, endDate: string): string[] {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const dateArray: string[] = [];

    if (startDateObj > endDateObj) {
      throw new Error("Start date should be before or equal to end date");
    }

    let currentDate = new Date(startDateObj);

    while (currentDate <= endDateObj) {
      const formattedDate = `${currentDate.getFullYear()}-${(
        currentDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${currentDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;

      dateArray.push(formattedDate);

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  }

  private findGraphElementByDate(
    dateArr: TransformedDataItem[],
    searchDate: string
  ): TransformedDataItem | null {
    const dateItem = dateArr.find((dateItem) => dateItem.xValue === searchDate);

    return dateItem ? dateItem : null;
  }

  private getFilledDatesInJsonGraph(
    data: TransformedDataItem[],
    fromDate: string,
    toDate: string
  ): TransformedDataItem[] {
    const graphDate = this.generateDateArray(fromDate, toDate);

    const convertedGraphsDate = graphDate.map((dateItem) => {
      const graphObjFound = this.findGraphElementByDate(data, dateItem);

      const result: TransformedDataItem = {
        xValue: dateItem,
        yValue: {
          label: "Value",
          value: 0,
        },
      };

      if (graphObjFound) {
        result.yValue.value = graphObjFound.yValue.value;
      }

      return result;
    });

    return convertedGraphsDate;
  }

  private async getJsonGraphDate(
    label: string,
    fromDate: string,
    toDate: string,
    params2: string
  ) {
    fromDate = new Date(fromDate).toISOString().split("T")[0];
    toDate = new Date(toDate).toISOString().split("T")[0];
    const graphsResult = await this.getConvertedInJsonGraphs(label, {
      fromDate,
      toDate,
      param2: params2,
    });

    if (!graphsResult.data) {
      return null;
    }

    const convertedGraphsDate = this.getFilledDatesInJsonGraph(
      graphsResult.data,
      fromDate,
      toDate
    );

    graphsResult.data = convertedGraphsDate;

    return graphsResult;
  }

  async getProductsPerDayGraph(fromDate: string, toDate: string) {
    return await this.getJsonGraphDate(
      "No. of products",
      fromDate,
      toDate,
      "Products"
    );
  }

  async getCustomerPerDaysGraph(fromDate: string, toDate: string) {
    return await this.getJsonGraphDate(
      "Customer Count",
      fromDate,
      toDate,
      "Customer"
    );
  }

  async getRevenuePerDaysGraph(fromDate: string, toDate: string) {
    return await this.getJsonGraphDate("Revenue", fromDate, toDate, "Revenue");
  }

  async getValuePerDaysGraph(fromDate: string, toDate: string) {
    return await this.getJsonGraphDate(
      "Total Price",
      fromDate,
      toDate,
      "Value"
    );
  }

  async getInteractionsChartData(fromDate: string, toDate: string) {
    return await this.getJsonGraphDate(
      "No. of values",
      fromDate,
      toDate,
      "Interaction"
    );
  }

  async getWishlistItemsPerDayData(fromDate: string, toDate: string) {
    return await this.getJsonGraphDate(
      "No. of values",
      fromDate,
      toDate,
      "Wishlist"
    );
  }
}
