import { Form, Switch, InputNumber, Button, Col, Row, Input, Spin } from "antd";
import { useNotificationForm } from "../../hooks/useNotificationForm";
import { NotificationFormProps } from "../../utils/types";
import { MIN_DELAY_MINUTES } from "../../utils/constants";
import { useEffect, useState } from "react";

export const BackInStockNotification = ({ initialValues, onSubmit, notificationValues, notifSaveLoading, fieldOnChange }: NotificationFormProps) => {
   const [currentValues, setCurrentValues] = useState(initialValues);
   const { form, handleChangeSwitch, handleChangeField, handleCancelBtn } = useNotificationForm({
      formName: "BACK_IN_STOCK",
      onSubmit: async (values, type) => {
         await onSubmit(values, type);
         const updatedValues = { ...values };
         setCurrentValues(updatedValues);
         form.setFields(
            Object.keys(values).map((key) => ({
               name: key,
               touched: false,
            }))
         );
      },
   });

   const isDotdigital = "apiUser" in notificationValues || "job_parameters1" in notificationValues;
   const isSendGrid = "job_parameters1" in notificationValues;

   useEffect(() => {
      if (initialValues && Object.keys(initialValues).length > 0) {
         form.setFieldsValue(initialValues);
         setCurrentValues(initialValues);
         form.setFields(
            Object.keys(initialValues).map((key) => ({
               name: key,
               touched: false,
            }))
         );
      }
   }, [initialValues, form]);

   useEffect(() => {
      if (currentValues && Object.keys(currentValues).length > 0) {
         form.setFieldsValue(currentValues);
         form.setFields(
            Object.keys(currentValues).map((key) => ({
               name: key,
               touched: false,
            }))
         );
      }
   }, [currentValues, form]);

   const numberParser = (string: string | undefined) => {
      const num = string ? Math.floor(Number(string)) : 0;
      return isNaN(num) ? 0 : num;
   };

   const isActive = Form.useWatch("active", form);
   const configDelay = Form.useWatch("configurable_delay", form);
   const minStockLevel = Form.useWatch("minStockLevelSchedule", form);
   const outOfStock = Form.useWatch("OutOfStock", form);
   const hasChanges = form.isFieldsTouched(["active", "configurable_delay", "minStockLevelSchedule", "OutOfStock", "template_id", "job_parameters"]);

   return (
      <Spin spinning={!!notifSaveLoading.BACK_IN_STOCK}>
         <Form form={form} onFinish={(val) => onSubmit(val, "BACK_IN_STOCK")} autoComplete="off">
            <Row gutter={[16, 16]}>
               <Col span={24}>
                  <p style={{ margin: 0 }}>
                     Send a <b>back in stock</b> notification to your customers when there are items in their Wishlist that had previously sold out
                     and are now back in stock.
                  </p>
               </Col>
               <Col span={24}>
                  <Form.Item name="active" valuePropName="checked" className="input-control !mb-0">
                     <Switch
                        size="small"
                        className="enable-switch"
                        checked={isActive}
                        onChange={(e) =>
                           handleChangeSwitch(
                              e,
                              "active",
                              isDotdigital
                                 ? ["configurable_delay", "minStockLevelSchedule", "OutOfStock", "template_id"]
                                 : ["configurable_delay", "minStockLevelSchedule", "OutOfStock"]
                           )
                        }
                     />
                     &nbsp;&nbsp;
                     <span>Send back in stock notifications</span>
                  </Form.Item>
               </Col>
               {!isDotdigital && (
                  <>
                     <Col span={24}>
                        <Form.Item
                           name="configurable_delay"
                           className="input-control !mb-0"
                           rules={[
                              {
                                 required: isActive,
                                 message: "This field is required!",
                              },
                              {
                                 validator: (rule, value, callback) => {
                                    if (isNaN(value)) {
                                       callback("Please enter a valid number");
                                    } else if (value < MIN_DELAY_MINUTES) {
                                       callback(`Number should be greater than or equal to ${MIN_DELAY_MINUTES}`);
                                    } else {
                                       callback();
                                    }
                                 },
                              },
                           ]}
                        >
                           <span className="before:content-['Delay_back_in_stock_notifications_by'] after:content-['minutes_(required)']">
                              <InputNumber
                                 parser={numberParser}
                                 min={0}
                                 className="!mx-2"
                                 disabled={!isActive}
                                 value={configDelay}
                                 onChange={(e) => handleChangeField(e, "configurable_delay")}
                              />
                           </span>
                        </Form.Item>
                     </Col>
                  </>
               )}
               <Col span={24}>
                  <Form.Item
                     name="minStockLevelSchedule"
                     className="input-control !mb-0"
                     rules={[
                        {
                           required: isActive,
                           message: "This field is required!",
                        },
                        {
                           validator: (rule, value, callback) => {
                              if (isNaN(value)) {
                                 callback("Please enter a valid number");
                              } else {
                                 callback();
                              }
                           },
                        },
                     ]}
                  >
                     <span className="before:content-['Minimum_stock_level_for_the_back_in_stock_notification_to_be_triggered'] after:content-['(required)']">
                        <InputNumber
                           parser={numberParser}
                           min={0}
                           className="!mx-2"
                           disabled={!isActive}
                           value={minStockLevel}
                           onChange={(e) => handleChangeField(e, "minStockLevelSchedule")}
                        />
                     </span>
                  </Form.Item>
               </Col>
               {/* <Col span={24}>
             <Form.Item name="OutOfStock" valuePropName="checked" className="input-control !mb-0">
               <Switch
                 size="small"
                 className="enable-switch"
                 checked={outOfStock}
                 disabled={!isActive}
                 onChange={(e) => handleChangeField(e, "OutOfStock")}
               />
               &nbsp;&nbsp;
               <span>Send notification only if item was out of stock</span>
             </Form.Item>
           </Col> */}
               {isDotdigital && (
                  <Col span={24} className="!flex !items-center !gap-2">
                     <Form.Item
                        label="Template ID"
                        name="template_id"
                        className="keys-form-item !mb-1"
                        rules={[
                           {
                              required: isActive,
                              message: "Please input a valid template ID",
                           },
                        ]}
                     >
                        <Input disabled={!isActive} />
                     </Form.Item>
                  </Col>
               )}
               {isSendGrid && (
                  <Col span={24} className="!flex !items-center !gap-2">
                     <Form.Item
                        label="UTM Parameters"
                        name="job_parameters"
                        className="keys-form-item !mb-1"
                        rules={[
                           {
                              required: false,
                              message: "Please input a valid job parameters",
                           },
                        ]}
                     >
                        <Input disabled={!isActive} />
                     </Form.Item>
                  </Col>
               )}
               <div className="!w-full !flex !justify-end">
                  <Button hidden={!hasChanges} onClick={() => handleCancelBtn()} className="hover:!border-[#59c3b8] hover:!text-[#59c3b8]">
                     Cancel
                  </Button>
                  <Button
                     type="primary"
                     className="save-btn !mx-2"
                     onClick={() => form.submit()}
                     loading={!!notifSaveLoading.BACK_IN_STOCK}
                     hidden={!hasChanges}
                  >
                     Save Changes
                  </Button>
               </div>
            </Row>
         </Form>
      </Spin>
   );
};
