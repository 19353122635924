import { useState } from "react";
import { DatePicker, Select, Space } from "antd";
import { useLocations } from "../../../../components/UserAdministration/libs/useLocations";
import { DateBeforeDays } from "../../Dashboard/components/Utils";
import { filterOptions } from "../../QuoteDashboard/utils/filterOptions";

interface DateRange {
   from: string;
   to: string;
}

const useStoreFilter = () => {
   const { locations } = useLocations();
   const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
   const [filterValue, setFilterValue] = useState<string>("30");
   const [dateValue, setDateValue] = useState<any>(null);
   const [dateRange, setDateRange] = useState<DateRange>({
      from: DateBeforeDays(30),
      to: DateBeforeDays(0),
   });

   const showCustomDateRange = filterValue === "custom";

   const handleFilterChange = (value: string) => {
      setFilterValue(value);
      if (value !== "custom") {
         setDateRange({
            from: DateBeforeDays(Number(value)),
            to: DateBeforeDays(0),
         });
      }
   };

   const handleDateRangeChange = (dates: any, dateStrings: string[]) => {
      setDateValue(dates);
      if (dates) {
         setDateRange({ from: dateStrings[0], to: dateStrings[1] });
      }
   };

   const filter = (
      <Space wrap>
         {showCustomDateRange && <DatePicker.RangePicker value={dateValue} onChange={handleDateRangeChange} />}
         <Select
            placeholder="Filter"
            className="w-full"
            value={filterValue}
            onChange={handleFilterChange}
            options={filterOptions}
         />
         <Select
            mode="tags"
            placeholder="Location"
            className="w-[200px]"
            value={selectedLocation}
            onChange={setSelectedLocation}
            options={locations?.locations
               ?.filter((location) => location?.name)
               .map((location) => ({
                  value: location?.locationRef,
                  label: location?.name,
               }))}
         />
      </Space>
   );

   return {
      selectedLocation,
      filterValue,
      dateRange,
      dateValue,
      handleFilterChange,
      handleDateRangeChange,
      filter,
   };
};

export default useStoreFilter;
