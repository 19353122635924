import { LocalStorageKeys } from "../../authServices/jwt/libs/LocalStorage.enum";
import { CategoryKeys } from "../../configs/data/category.enum";
import { RolePermissions } from "../../configs/rolePemisions";
import { Roles } from "../../configs/rolePemisions/roles.enum";
import { checkModuleAccess, Modules } from "../helpers/checkModuleAccess";

export const getUserVisitPermission = (roles: string[], pageName: string): boolean => {
  if (roles.includes(Roles.TwcSuperUser)) {
    return true;
  }

  if (pageName === CategoryKeys.APP_CONFIGURATIONS) {
    const allowedRoles = [Roles.TwcGroupOwner, Roles.TwcStoreOwner, Roles.TwcSuperUser];
    if (!roles.some(role => allowedRoles.includes(role as Roles))) {
      return false;
    }

    try {
      const twcConfig = JSON.parse(localStorage.getItem(LocalStorageKeys.TWC_CONFIG) ?? '{}');
      return Boolean(twcConfig?.shopifyToken?.length);
    } catch (error) {
      console.error('Error parsing TWC config:', error);
      return false;
    }
  }
  if (pageName === CategoryKeys.STORE_STAFF_DASHBOARD || pageName === CategoryKeys.TOP_STORES_DASHBOARD) return checkModuleAccess(Modules.STORE_POS);
  if (pageName === CategoryKeys.QUOTE_DASHBOARD) return checkModuleAccess(Modules.QUOTES);

  if (pageName === CategoryKeys.MANAGE_STORES) return checkModuleAccess(Modules.STORE_REPORTS) || checkModuleAccess(Modules.STORE_POS);

  if (pageName === CategoryKeys.MANAGE_STORE_STAFF) {
    const hasAccess = [
      Modules.STORE_POS,
      Modules.NEWSTORE,
      Modules.SHOPIFY_POS
    ].some(module => checkModuleAccess(module));

    return hasAccess;
  }

  if (pageName === CategoryKeys.STORE_MESSAGE_TEMPLATES) return checkModuleAccess(Modules.MESSAGES);

  if (pageName === CategoryKeys.TASK_MANAGEMENT) return checkModuleAccess(Modules.TASKS);

  const allowedPages: string[] = roles.reduce((acc, role) => {
    const pages = RolePermissions[role as keyof typeof RolePermissions];
    return pages ? acc.concat(pages) : acc;
  }, [] as string[]);

  return allowedPages.includes(pageName);
};
