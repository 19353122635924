import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { ClickHouseDashboardApi, DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import { StaffChartType } from "../../Dashboard/SimpleBoxMain/clickHouseApi/types";

interface DataItem {
   staff_name: string;
   interaction_count: number;
}

const BarChartWrapper: React.FC<{ dateRange: DateRange; selectedLocation: string[]; interactionType: StaffChartType; title?: string }> = ({
   dateRange,
   selectedLocation,
   interactionType,
   title,
}) => {
   const [rawData, setRawData] = useState<DataItem[]>([]);

   useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
         const response = await ClickHouseDashboardApi.getStaffGraphData(dateRange, interactionType, selectedLocation);

         if (isMounted) {
            setRawData(JSON.parse(response).dashboardChart);
         }
      };
      fetchData();

      return () => {
         isMounted = false;
      };
   }, [dateRange, selectedLocation, interactionType]);

   const data = rawData
      ?.filter((item) => item.staff_name)
      ?.map((item) => ({
         staffName: item.staff_name,
         interactions: Number(item.interaction_count),
      }));

   return (
      <div style={{ overflowX: "auto" }}>
         <h3>{title}</h3>
         <div style={{ width: "100%" }}>
            <ResponsiveContainer width="100%" height={400}>
               <BarChart
                  data={data}
                  margin={{
                     top: 5,
                     right: 10,
                     left: 10,
                     bottom: 5,
                  }}
               >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="staffName" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="interactions" fill="#8884d8" />
               </BarChart>
            </ResponsiveContainer>
         </div>
      </div>
   );
};

export default BarChartWrapper;
