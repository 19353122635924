import { CategoryKeys } from "../../configs/data/category.enum";
import { Roles } from "../../configs/rolePemisions/roles.enum";
import { checkModuleAccess, Modules } from "../helpers/checkModuleAccess";

export type SubCategory = { key: string; tooltrip?: string };

export const getFilterSubmenuByRole = (
  roles: string[],
  items: SubCategory[]
) => {
  if (roles.includes(Roles.TwcSuperUser)) {
    return items;
  }

  let filteredItems = items;

  if (!checkModuleAccess(Modules.QUOTES)) {
    filteredItems = filteredItems.filter((item) => item.key !== "quotes" && item.key !== "quote-conversion");
  }

  if (!checkModuleAccess(Modules.STORE_POS)) {
    filteredItems = filteredItems.filter((item) => item.key !== "staff" && item.key !== "top-customer-by-spend");
  }

  if (!checkModuleAccess(Modules.PREFERENCES)) {
    filteredItems = filteredItems.filter((item) => item.key !== "preferences");
  }

  if (!checkModuleAccess(Modules.MESSAGES) && !checkModuleAccess(Modules.STORE_POS)) {
    filteredItems = filteredItems.filter((item) => item.key !== "customer-message");
  }

  if (!checkModuleAccess(Modules.TASKS)) {
    filteredItems = filteredItems.filter((item) => item.key !== CategoryKeys.TASK_MANAGEMENT_AUTOMATED_TASKS && item.key !== CategoryKeys.TASK_MANAGEMENT_MANUAL_TASKS && item.key !== CategoryKeys.TASK_MANAGEMENT_TEMPLATES && item.key !== CategoryKeys.TASK_MANAGEMENT_TASK_STATUS);
  }

  return filteredItems;
};

