interface NotificationHeaderProps {
  title: string;
  description: string;
  text: string;
}

export const NotificationHeader = ({ title, description, text }: NotificationHeaderProps) => {
  return (
      <div className="!col-span-1">
        <h1 className="font-medium text-xl text-gray-600">{title}</h1>
        <div className="text-base font-bold text-gray-500">{description}</div>
        <div className="text-sm text-gray-600">{text}</div>
      </div>
  );
}; 