import axios from "axios";
import { StorageKeys } from "../../../../constant/storageKeys";
import { userManagementService } from "../../../../api/services/userManagement/usere-management.service";
import { message } from "antd";

export const BASE_AUTH_API = process.env.REACT_APP_AUTH_BASE_API;

export const axiosAuth = axios.create({
  baseURL: BASE_AUTH_API,
});

axiosAuth.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(StorageKeys.UserManagementAccess);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    const errorStatuses = [401, 403, 400, 500];
    if (errorStatuses.includes(error.response.status)) {
      if (window.location.pathname !== "/auth/sign-in") {
        window.location.href = '/auth/sign-in';
      }
      window.localStorage.clear();
    }

    if (error.response && !originalRequest._retry) {
      originalRequest._retry = true;

      const newToken = await userManagementService.getAuthToken();

      if (newToken) {
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return axiosAuth(originalRequest);
      }
    } else if (error.response.status === 503) {
      message.error("You have been logged out. Please log in again");
      if (window.location.pathname !== "/auth/sign-in") {
        window.location.href = '/auth/sign-in';
      }
    }

    return Promise.reject(error);
  }
);