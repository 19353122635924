export const formatXAxis = (tickItem: string) => {
   if (!tickItem) return "";
   const date = new Date(tickItem);
   return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
   });
};

export const truncateText = (text: string, length: number) => {
   return text.length > length ? `${text.substring(0, length)}...` : text;
};