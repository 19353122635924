import { Form, Switch, Button, Col, Row, Input, Spin } from "antd";
import { useNotificationForm } from "../../hooks/useNotificationForm";
import { NotificationFormProps } from "../../utils/types";
import { useEffect, useState } from "react";

export const ComingSoonNotification = ({ initialValues, onSubmit, notificationValues, notifSaveLoading, fieldOnChange }: NotificationFormProps) => {
   const [currentValues, setCurrentValues] = useState(initialValues);
   const { form, handleChangeSwitch, handleChangeField, handleCancelBtn } = useNotificationForm({
      formName: "COMING_SOON",
      onSubmit: async (values, type) => {
         await onSubmit(values, type);
         const updatedValues = { ...values };
         setCurrentValues(updatedValues);
         form.setFields(
            Object.keys(values).map((key) => ({
               name: key,
               touched: false,
            }))
         );
      },
   });

   const isDotdigital = "apiUser" in notificationValues || "job_parameters1" in notificationValues;
   const isSendGrid = "job_parameters1" in notificationValues;

   useEffect(() => {
      if (initialValues && Object.keys(initialValues).length > 0) {
         form.setFieldsValue(initialValues);
         setCurrentValues(initialValues);
         form.setFields(
            Object.keys(initialValues).map((key) => ({
               name: key,
               touched: false,
            }))
         );
      }
   }, [initialValues, form]);

   useEffect(() => {
      if (currentValues && Object.keys(currentValues).length > 0) {
         form.setFieldsValue(currentValues);
         form.setFields(
            Object.keys(currentValues).map((key) => ({
               name: key,
               touched: false,
            }))
         );
      }
   }, [currentValues, form]);

   const isActive = Form.useWatch("active", form);
   const templateId = Form.useWatch("template_id", form);
   const hasChanges = form.isFieldsTouched(["active"]) || (isActive && templateId !== undefined);

   return (
      <Spin spinning={!!notifSaveLoading.COMING_SOON}>
         <Form form={form} onFinish={(val) => onSubmit(val, "COMING_SOON")} autoComplete="off">
            <Row gutter={[16, 16]}>
               <Col span={24}>
                  <p style={{ margin: 0 }}>
                     <b>Coming soon</b> is typically used create a subscription list of customers that are interested in an upcoming product range.
                     This is not an automated alert. Instead, you can manually generate a list of subscribers or send a current list to your marketing
                     platform at any time.
                  </p>
               </Col>
               <Col span={24}>
                  <Form.Item name="active" valuePropName="checked" className="input-control !mb-0">
                     <Switch
                        size="small"
                        className="enable-switch"
                        checked={isActive}
                        onChange={(e) => handleChangeSwitch(e, "active", isDotdigital ? ["template_id"] : [])}
                     />
                     &nbsp;&nbsp;
                     <span>Enable Coming Soon subscriptions</span>
                  </Form.Item>
               </Col>
               {isDotdigital && (
                  <Col span={24} className="!flex !items-center !gap-2">
                     <Form.Item
                        label="Template ID"
                        name="template_id"
                        className="keys-form-item !mb-1"
                        rules={[
                           {
                              required: isActive,
                              message: "Please input a valid template ID",
                           },
                        ]}
                     >
                        <Input disabled={!isActive} />
                     </Form.Item>
                  </Col>
               )}
               {isSendGrid && (
                  <Col span={24} className="!flex !items-center !gap-2">
                     <Form.Item
                        label="UTM Parameters"
                        name="job_parameters"
                        className="keys-form-item !mb-1"
                        rules={[
                           {
                              required: false,
                              message: "Please input a valid job parameters",
                           },
                        ]}
                     >
                        <Input disabled={!isActive} />
                     </Form.Item>
                  </Col>
               )}
               <div className="!w-full !flex !justify-end">
                  <Button hidden={!hasChanges} onClick={() => handleCancelBtn()} className="hover:!border-[#59c3b8] hover:!text-[#59c3b8]">
                     Cancel
                  </Button>
                  <Button
                     type="primary"
                     className="save-btn !mx-2"
                     onClick={() => form.submit()}
                     loading={!!notifSaveLoading.COMING_SOON}
                     hidden={!hasChanges}
                  >
                     Save Changes
                  </Button>
               </div>
            </Row>
         </Form>
      </Spin>
   );
};
