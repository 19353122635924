export const useTransformBarData = (
   rawData: any[],
   options: {
      groupByField: string;
      sumFields: string[];
      interactionType: string;
   }
) => {
   const { groupByField, sumFields, interactionType } = options;

   if (!rawData || rawData.length === 0) return [];

   const groupedData: Record<string, Record<string, number | string>> = {};

   const interactionHandlers: Record<string, (item: any, field: string) => number> = {
      INTERACTION_BY_STORE: (item, field) => (item.entityType === field ? Number(item.interaction_count) || 0 : 0),
      INTERACTION_BY_STAFF: (item, field) => (item.entityType === field ? Number(item.interaction_count) || 0 : 0),
      CONVERSION_STORE_NUMBER: (item, field) => (item.source === field ? Number(item.conversions) || 0 : 0),
      CONVERSION_STORE_VALUE: (item, field) => (item.source === field ? Number(item.totalAmount) || 0 : 0),
   };

   const handleInteraction = interactionHandlers[interactionType];

   rawData.forEach((item) => {
      const groupKey = item[groupByField];
      const groupName = item.locationName || item.staff_name || "Unknown";

      if (!groupKey || !groupName) return;

      if (!groupedData[groupKey]) {
         groupedData[groupKey] = {
            [groupByField]: groupName,
         };
      }

      sumFields.forEach((field) => {
         const interactionValue = handleInteraction ? handleInteraction(item, field) : 0;

         if (!groupedData[groupKey][field]) {
            groupedData[groupKey][field] = 0;
         }

         (groupedData[groupKey][field] as number) += interactionValue;
      });
   });

   return Object.values(groupedData);
};
