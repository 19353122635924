import { lazy, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Loading from "./components/UI/Loading";

import { userManagementService } from "./api/services/userManagement/usere-management.service";
import { CategoryKeys } from "./configs/data/category.enum";
import { Roles } from "./configs/rolePemisions/roles.enum";

import { GetAuthStateContext } from "./utility/context/authContext";
import { GetShopStateContext } from "./utility/context/shopContext";
import useAuthenticateAndLoadShop from "./utility/hooks/useAuthenticateAndLoadShop";
import { useKeycloakUser } from "./utility/hooks/useKeycloakUser";
import { useAuthChecking } from "./utility/router/useAuthChecking";
import { useRouteManagement } from "./utility/router/useRouteManagement";
import { refreshToken } from "./utility/store/middlerware/authMiddleware";
import { getKlaviyoIntegration } from "./utility/store/middlerware/mainMiddleware";

const Outlet = lazy(() => import("./views/pages/Outlet"));
const Dashboard = lazy(() => import("./views/pages/Dashboard"));
const Reporting = lazy(() => import("./views/pages/Reporting"));
const AppIntegrations = lazy(() => import("./views/pages/AppIntegrations/pages/KlaviyoIntegration"));
const Integration = lazy(() => import("./views/pages/Integration"));
const DiagnosticReport = lazy(() => import("./views/pages/DiagnosticReport"));
const Documentation = lazy(() => import("./views/pages/Documentation"));
const Configuration = lazy(() => import("./views/pages/Configuration"));
const Subscription = lazy(() => import("./views/pages/Subscription"));
const MessageTemplates = lazy(() => import("./views/pages/MessageTemplates"));
const ManageStores = lazy(() => import("./views/pages/ManageStores"));
const QuoteDashboard = lazy(() => import("./views/pages/QuoteDashboard"));
const StoreStaffDashboard = lazy(() => import("./views/pages/Store&Staff"));
const ManageTasksTemplates = lazy(() => import("./views/pages/Tasking/ManageTasksTemplates"));
const ManualTasks = lazy(() => import("./views/pages/Tasking/ManualTasks"));
const AutomatedTasks = lazy(() => import("./views/pages/Tasking/AutomatedTasks"));
const TaskStatus = lazy(() => import("./views/pages/Tasking/TaskStatus"));
const TopStoresDashboard = lazy(() => import("./views/pages/TopStoresDashboard"));
const WebhookManagement = lazy(() => import("./views/pages/WebhookManagement"));
const SendGridIntegration = lazy(() => import("./views/pages/AppIntegrations/pages/SendGridIntegration"));
const DotDigital = lazy(() => import("./views/pages/AppIntegrations/pages/DotdigitalIntegration"));
const AddUser = lazy(() => import("./components/UserAdministration/AddUser"));
const AddStaff = lazy(() => import("./components/UserAdministration/AddStaff"));
const ListManagement = lazy(() => import("./views/pages/ListManagement"));

function AppRouter() {
  const { integrationResponse } = GetShopStateContext();
  const { authState, dispatch } = GetAuthStateContext();
  const { shopSingleState, integrationDispatch } = GetShopStateContext();
  const { checkPageAccess, checkPermission, checkAuth } = useAuthChecking();

  useEffect(() => {
    userManagementService.getAuthToken();
  }, []);

  useEffect(() => {
    if (!shopSingleState.detail?.shop_hash || !authState.accessToken) return;

    getKlaviyoIntegration(integrationDispatch); // Klaviyo integration for hide notification menu and routing
  }, [
    shopSingleState.detail?.shop_hash,
    integrationDispatch,
    authState.accessToken,
  ]);

  useEffect(() => {
    const handleRefreshToken = () => {
      if (
        !authState.auth ||
        !authState.auth.status ||
        authState.auth.status.includes(Roles.OldAuthMethod)
      ) {
        return;
      }

      refreshToken(dispatch).catch((error) => {
        console.error("Error refreshing token", error);
      });
    };

    const refreshInterval = window.setInterval(
      handleRefreshToken,
      5 * 60 * 1000
    );

    return () => {
      window.clearInterval(refreshInterval);
    };
  }, []);

  const loading = useAuthenticateAndLoadShop();
  const keycloakLoading = useKeycloakUser();
  const {
    AuthComponent,
    ConfigEmailNotificationComponent,
    NotFoundComponent,
    PlansComponent,
    QuoteNotificationComponent,
    SelectShopComponent,
    SelectTenantComponent
  } = useRouteManagement(checkPageAccess);

  if (loading || keycloakLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={() => <AuthComponent />} />
        <Route path="/select-shop" render={() => <SelectShopComponent />} />
        <Route path="/select-tenant" render={() => <SelectTenantComponent/>} />
        <Route path="/plans" render={() => <PlansComponent />} />
        <Route exact path="/" render={() => checkAuth(<Dashboard />)} />
        <Route
          path="/pos-config"
          render={() => checkPermission(<Outlet />, CategoryKeys.POS_CONFIG)}
        />
        <Route
          path="/wishlist-dashboard"
          render={() => checkPageAccess(<Dashboard />, CategoryKeys.WISHLIST_DASHBOARD)}
        />
        <Route
          path="/top-stores-dashboard"
          render={() => checkPageAccess(<TopStoresDashboard />, CategoryKeys.TOP_STORES_DASHBOARD)}
        />
        <Route
          path="/store-&-staff-dashboard"
          render={() => checkPageAccess(<StoreStaffDashboard />, CategoryKeys.STORE_STAFF_DASHBOARD)}
        />
        <Route
          path="/quote-dashboard"
          render={() => checkPageAccess(<QuoteDashboard />, CategoryKeys.QUOTE_DASHBOARD)}
        />
        <Route
          path="/subscription"
          render={() =>
            checkPageAccess(<Subscription />, CategoryKeys.SUBSCRIPTION)
          }
        />
        <Route
          path="/manage-users"
          render={() =>
            checkPageAccess(<AddUser />, CategoryKeys.MANAGE_USERS)
          }
        />
        <Route
          path="/manage-store-staff"
          render={() =>
            checkPageAccess(<AddStaff />, CategoryKeys.MANAGE_STORE_STAFF)
          }
        />
        {/* For now it should not be displayed */}
        {/* <Route
          path="/notifications/quotes"
          render={() => <QuoteNotificationComponent />}
        />
        {!integrationResponse.activate && (
          <Route
            exact
            path="/notifications/wishlist"
            render={() => <ConfigEmailNotificationComponent />}
          />
        )} */}
        <Route
          path="/reporting"
          render={() => checkPageAccess(<Reporting />, CategoryKeys.REPORTING)}
        />
        <Route
          path="/integrations/klaviyo"
          render={() =>
            checkPageAccess(<AppIntegrations />, CategoryKeys.INTEGRATIONS)
          }
        />
        <Route
          path="/integrations/dotdigital"
          render={() =>
            checkPageAccess(<DotDigital />, CategoryKeys.INTEGRATIONS)
          }
        />
        <Route
          path="/integrations/sendgrid"
          render={() =>
            checkPageAccess(<SendGridIntegration />, CategoryKeys.INTEGRATIONS)
          }
        />
        <Route
          path="/integrations"
          render={() =>
            checkPageAccess(<Integration />, CategoryKeys.INTEGRATIONS)
          }
        />
        <Route
          path="/documentation"
          render={() =>
            checkPageAccess(<Documentation />, CategoryKeys.DOCUMENTATION)
          }
        />
        <Route
          path="/diagnostics"
          render={() => checkAuth(<DiagnosticReport />)}
        />
        <Route
          path="/app-configurations"
          render={() =>
            checkPageAccess(<Configuration />, CategoryKeys.APP_CONFIGURATIONS)
          }
        />
        <Route
          path="/manage-stores-&-regions"
          render={() => checkPageAccess(<ManageStores />, CategoryKeys.MANAGE_STORES)}
        />
        <Route
          path="/store-message-templates"
          render={() => checkPageAccess(<MessageTemplates/>, CategoryKeys.STORE_MESSAGE_TEMPLATES)}
        />
        {/* <Route
          path="/task-management/manage-templates"
          render={() => checkPageAccess(<ManageTasksTemplates/>, CategoryKeys.TASK_MANAGEMENT_TEMPLATES)}
        />
        <Route
          path="/task-management/manual-tasks"
          render={() => checkPageAccess(<ManualTasks/>, CategoryKeys.TASK_MANAGEMENT_MANUAL_TASKS)}
        />
        <Route
          path="/task-management/automated-tasks"
          render={() => checkPageAccess(<AutomatedTasks/>, CategoryKeys.TASK_MANAGEMENT_AUTOMATED_TASKS)}
        />
        <Route
          path="/task-management/task-status"
          render={() => checkPageAccess(<TaskStatus/>, CategoryKeys.TASK_MANAGEMENT_TASK_STATUS)}
        /> */}
        <Route
          path="/webhook-management"
          render={() => checkPageAccess(<WebhookManagement/>, CategoryKeys.WEBHOOK_MANAGEMENT)}
        />
        {/* <Route
          path="/list-management"
          render={() => checkPageAccess(<ListManagement/>, CategoryKeys.LIST_MANAGEMENT)}
        /> */}
        <Route exact path="/404" render={() => <NotFoundComponent />} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  );
}

export default AppRouter;
