import React from "react";
import { Tooltip } from "antd";

interface CardProps {
   title: string;
   value: any;
   loading: boolean;
   tooltip?: string;
}

const Card: React.FC<CardProps> = React.memo(({ title, value, loading, tooltip }) => (
   <Tooltip title={tooltip || ""} placement="top">
      <div className="bg-white px-4 py-6 rounded-md shadow-md text-center grid items-center">
         {loading ? (
            <div className="animate-pulse h-[20px] bg-gray-200 rounded w-32 m-auto mb-3"></div>
         ) : (
            <p className="text-base font-semibold mb-3">{title}</p>
         )}
         {loading ? (
            <div className="animate-pulse h-6 bg-gray-200 rounded w-20 m-auto mb-0"></div>
         ) : (
            <p className="text-xl font-bold mb-0">{value}</p>
         )}
      </div>
   </Tooltip>
));

export default Card;
