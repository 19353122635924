import { useState, useEffect } from 'react';
import { message } from 'antd';
import { INITIAL_FIELD_CHANGE_STATE, INITIAL_NOTIFICATION_LOADING_STATE, NOTIFICATION_STATUS } from '../utils/constants';
import { NotificationType } from '../utils/types';
import { getDotDigitalUser, postDotDigitalCredentials } from '../../../../utility/store/effect/dotDigitalIntegrations';
import { getNotificationDetails, postNotificationDetails } from '../../../../utility/store/effect/integrations';
import { sessionShopHash } from '../../../../authServices/jwt';
import { convertDaysAndMinutes } from '../../../../utility/helpers/convertDaysAndMinutes';
import { DotdigitalUser } from '../utils/types';

interface NotificationDetail {
  id: string;
  notification: NotificationType;
  notificationService: string;
  schedule: string;
  is_active: boolean;
  is_scheduled: boolean;
  deleted: boolean;
  outOfStock: boolean;
  configurable_delay?: string;
  controlGroup: boolean;
  stockThreshold?: number;
  notifyMeThreshold?: number;
  template_id?: string;
}

export const useDotdigitalIntegration = () => {
  const [keyLoaded, setKeyLoad] = useState<boolean>(false);
  const [dataLoaded, setDataLoad] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [errorMsg, setKeyError] = useState<string>("");
  const [saveBtnLoading, setSaveBtnLoading] = useState<boolean>(false);
  const [notifSaveLoading, setNotifSaveLoading] = useState(INITIAL_NOTIFICATION_LOADING_STATE);
  const [fieldOnChange, setOnChange] = useState(INITIAL_FIELD_CHANGE_STATE);
  const [status, setStatus] = useState<string[]>([NOTIFICATION_STATUS.initialized]);
  const [dotdigitalUser, setDotdigitalUser] = useState<DotdigitalUser>({} as DotdigitalUser);

  useEffect(() => {
    handleGetKeys();
    getData();
  }, []);

  const handleGetKeys = async () => {
    setKeyLoad(true);
    setKeyError("");
    try {
      const res = await getDotDigitalUser();
      setDotdigitalUser(res?.data);
      if (res?.data) {
        setStatus((e) => [...e, NOTIFICATION_STATUS.savedKeys]);
      }
    } catch (err: any) {
      setKeyError(err.response?.data?.title);
      message.warning("Warning - no authentication credentials found");
      console.log(err);
    } finally {
      setKeyLoad(false);
    }
  };

  const getData = async () => {
    // Set loading state for all notification types
    setNotifSaveLoading(
      Object.keys(notifSaveLoading).reduce((acc, key) => ({ 
        ...acc, 
        [key]: true 
      }), INITIAL_NOTIFICATION_LOADING_STATE)
    );
    try {
      const params = new URLSearchParams([
        ["notificationService", "DOTDIGITAL"],
        ["tenantId", sessionShopHash.getTenant() || ""],
      ]);
      const res = await getNotificationDetails(params);
      if (res?.data) {
        handleUpdate(res?.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      // Reset loading state for all notification types
      setNotifSaveLoading(
        Object.keys(notifSaveLoading).reduce((acc, key) => ({ 
          ...acc, 
          [key]: false 
        }), INITIAL_NOTIFICATION_LOADING_STATE)
      );
    }
  };

  const handleUpdate = (data: NotificationDetail[]) => {
    setData(data);
  };

  const handleSubmitKeys = async (values: DotdigitalUser) => {
    setSaveBtnLoading(true);
    setKeyError("");
    try {
      await postDotDigitalCredentials(values);
      setStatus((e) => [...e, NOTIFICATION_STATUS.savedKeys]);
      message.success("Successfully saved authentication details");
    } catch (err: any) {
      setKeyError(err.response?.data?.title);
      message.error(err.response?.data?.title || "Something went wrong ! Couldn't save authentication details");
      console.log(err);
    } finally {
      setSaveBtnLoading(false);
    }
  };

  const handleNotificationChanges = async (value: any, name: NotificationType) => {
    setNotifSaveLoading({ ...notifSaveLoading, [name]: true });
    try {
      const params = new URLSearchParams([
        ["notification", name],
        ["notificationService", "DOTDIGITAL"],
      ]);

      const req = {
        name,
        active: value.active,
        schedule: value.schedule,
        delaySchedule: value.delaySchedule,
        stockThreshold: value.minStockLevelSchedule,
        notificationService: "DOTDIGITAL",
        outOfStock: value.OutOfStock,
        template_id: value.template_id,
        configurable_delay: name === "REMINDER" ? convertDaysAndMinutes(value.configurable_delay, "days") : value.configurable_delay,
      };

      await postNotificationDetails(req, params);
      message.success("Successfully updated the notification details");
      setOnChange({ ...fieldOnChange, [name]: false });
    } catch (err) {
      message.error("Something went wrong ! Couldn't update the notification details");
      console.log(err);
    } finally {
      setNotifSaveLoading({ ...notifSaveLoading, [name]: false });
    }
  };

  return {
    keyLoaded,
    dataLoaded,
    errorMsg,
    saveBtnLoading,
    notifSaveLoading,
    fieldOnChange,
    status,
    dotdigitalUser,
    handleSubmitKeys,
    handleNotificationChanges,
    notificationDetails: data as NotificationDetail[],
    getData
  };
}; 