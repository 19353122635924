import { Card } from "antd";
import useStaffFilter from "./hooks/useStaffFilter";
import StaffBarChart from "./charts/StaffBarChart";
import Leaderboard from "./charts/LeaderBoard";
import StackedBarChartWrapper from "./charts/StackedBarChartWrapper";
import { StaffChartType } from "../Dashboard/SimpleBoxMain/clickHouseApi/types";

const StaffPerformance = () => {
   const { filter, dateRange, selectedStaff } = useStaffFilter();

   const chartData = [
      {
         title: "Staff Interactions",
         chart: <StackedBarChartWrapper isStaff multiple interactionType={StaffChartType.INTERACTION_BY_STAFF} dateRange={dateRange} selectedStaff={selectedStaff} />,
      },
      {
         title: "",
         chart: (
            <div className="grid xl:grid-cols-3 items-start">
               <StaffBarChart
                  title="Messages interactions"
                  interactionType={StaffChartType.MESSAGE_INTERACTION}
                  dateRange={dateRange}
                  selectedLocation={selectedStaff}
               />
               <StaffBarChart
                  title="Contact interactions"
                  interactionType={StaffChartType.CUSTOMER_INTERACTION}
                  dateRange={dateRange}
                  selectedLocation={selectedStaff}
               />
               <StaffBarChart
                  title="Wishlist interactions"
                  interactionType={StaffChartType.WISHLIST_INTERACTION}
                  dateRange={dateRange}
                  selectedLocation={selectedStaff}
               />
            </div>
         ),
      },
      {
         title: "Staff Rankings",
         chart: (
            <div className="grid xl:grid-cols-2 gap-5">
               <Leaderboard dateRange={dateRange} leaderboardType={StaffChartType.STAFF_CONVERSION_LEADERBOAD} />
               <Leaderboard dateRange={dateRange} leaderboardType={StaffChartType.STAFF_CONVERSION_VALUE_LEADERBOAD} />
            </div>
         ),
      },
   ];

   return (
      <div className="w-full bg-white">
         <div className="px-4 w-full">
            <Card title="Staff Performance" extra={filter}>
               {chartData.map((item, index) => (
                  <Card key={index}>
                     {item.title && <h3 className="font-semibold text-[18px]">{item.title}</h3>}
                     {item.chart}
                  </Card>
               ))}
            </Card>
         </div>
      </div>
   );
};

export default StaffPerformance;
