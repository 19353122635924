import React, { useEffect, useState, useMemo } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { ClickHouseDashboardApi, DateRange } from "../../Dashboard/SimpleBoxMain/clickHouseApi";
import { formatXAxis } from "../helpers";
import { ChartType } from "../../Dashboard/SimpleBoxMain/clickHouseApi/types";
import { Tooltip as AntTooltip } from "antd";


interface ChartData {
   D: string;
   C: string;
   value: string;
}

interface AreaChartWrapperProps {
   dateRange: DateRange;
   selectedLocation: string[];
   interactionType: ChartType;
   tooltip?: string;
}

const AreaChartWrapper: React.FC<AreaChartWrapperProps> = ({ dateRange, selectedLocation, interactionType, tooltip }) => {
   const [rawData, setRawData] = useState<ChartData[]>([]);

   useEffect(() => {
      let isMounted = true;

      const fetchData = async () => {
         try {
            const response = await ClickHouseDashboardApi.getLocationGraphData(dateRange, interactionType, selectedLocation);
            const data = JSON.parse(response).dashboardChart;

            if (isMounted) {
               setRawData(data);
            }
         } catch (error) {
            console.error("Failed to fetch data:", error);
         }
      };

      fetchData();

      return () => {
         isMounted = false;
      };
   }, [dateRange, selectedLocation, interactionType]);

   const transformData = useMemo(() => {
      if (!rawData || rawData.length === 0) return [];

      const isConversionByDay = interactionType === "CONVERSION_BYDAY_BYSTORE";

      return rawData
         .filter((item) => (isConversionByDay ? item.C !== "0" : item.value !== "0"))
         .map((item) => ({
            date: item.D,
            value: Number(isConversionByDay ? item.C : item.value),
         }));
   }, [rawData, interactionType]);

   return (
      <AntTooltip title={tooltip || ""} placement="left">
         <div style={{ overflowX: "auto" }}>
            <div style={{ width: "100%" }}>
               <ResponsiveContainer width="100%" height={400}>
                  <AreaChart
                     data={transformData}
                     margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                     }}
                  >
                     <CartesianGrid strokeDasharray="3 3" />
                     <XAxis dataKey="date" tickFormatter={formatXAxis} />
                     <YAxis />
                     <Tooltip />
                     <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
                  </AreaChart>
               </ResponsiveContainer>
            </div>
         </div>
      </AntTooltip>
   );
};

export default AreaChartWrapper;
